import { 
    GET_PRICE_DJUBLI_FAIL,
    GET_PRICE_DJUBLI_REQUEST,
    GET_PRICE_DJUBLI_SUCCESS,
    RESET_ALL_VALUE_FORM_AT_SELL_CAR,
    RESET_GROUP_MODEL_AT_SELL_CAR, 
    RESET_MERK_AT_SELL_CAR, 
    RESET_MODEL_AT_SELL_CAR, 
    RESET_MODEL_YEAR_AT_SELL_CAR, 
    RESET_YEAR_OF_USAGE_AT_SELL_CAR, 
    SELECT_EXTERIOR_COLOR_AT_SELL_CAR, 
    SELECT_GROUP_MODEL_AT_SELL_CAR, 
    SELECT_INTERIOR_COLOR_AT_SELL_CAR, 
    SELECT_MERK_AT_SELL_CAR, 
    SELECT_MODEL_AT_SELL_CAR, 
    SELECT_MODEL_YEAR_AT_SELL_CAR, 
    SELECT_YEAR_OF_USAGE_AT_SELL_CAR, 
    SET_KILOMETER_VALUE_AT_SELL_CAR} from "../constants/sellCarContants"

export const sellCarFormValueReducer = (
    state = { 
        brands: null,
        groupModel: null,
        model: null,
        modelYear: null,
        yearOfUsage: null,
        exteriorColor: null,
        interiorColor: null,
        kilometer: null,
        disableGroupModel: true,
        disableModel: true,
        disableModelYear: true,
        disableYearOfUsage: true
    },
    action
) => {
    switch (action.type) {
        case SELECT_MERK_AT_SELL_CAR:
            return { loading: false, ...state, brands: action.payload, disableGroupModel: false }
        case SELECT_GROUP_MODEL_AT_SELL_CAR:
            return { loading: false, ...state, groupModel: action.payload, disableModel: false }
        case SELECT_MODEL_AT_SELL_CAR:
            return { loading: false, ...state, model: action.payload, disableModelYear: false }
        case SELECT_MODEL_YEAR_AT_SELL_CAR:
            return { loading: false, ...state, modelYear: action.payload, disableYearOfUsage: false }
        case SELECT_YEAR_OF_USAGE_AT_SELL_CAR:
            return { loading: false, ...state, yearOfUsage: action.payload }
        case SELECT_EXTERIOR_COLOR_AT_SELL_CAR:
                return { loading: false, ...state, exteriorColor: action.payload }
        case SELECT_INTERIOR_COLOR_AT_SELL_CAR:
            return { loading: false, ...state, interiorColor: action.payload }
        case SET_KILOMETER_VALUE_AT_SELL_CAR:
            return { loading: false, ...state, kilometer: action.payload }
        case RESET_MERK_AT_SELL_CAR:
            return { loading: false, ...state, brands: null, disableGroupModel: true, disableYearOfUsage: true }
        case RESET_GROUP_MODEL_AT_SELL_CAR:
            return { loading: false, ...state, groupModel: null, disableModel: true }
        case RESET_MODEL_AT_SELL_CAR:
            return { loading: false, ...state, model: null, disableModelYear: true }
        case RESET_MODEL_YEAR_AT_SELL_CAR:
            return { loading: false, ...state, modelYear: null }
        case RESET_YEAR_OF_USAGE_AT_SELL_CAR:
            return { loading: false, ...state, yearOfUsage: null }
        case RESET_ALL_VALUE_FORM_AT_SELL_CAR:
            return { 
                loading: false,
                brands: null,
                groupModel: null,
                model: null,
                modelYear: null,
                yearOfUsage: null,
                exteriorColor: null,
                interiorColor: null,
                kilometer: null,
                disableGroupModel: true,
                disableModel: true,
                disableModelYear: true,
                disableYearOfUsage: true
            }
        default:
            return state
    }
}

export const getPriceByDjubliReducer = ( 
    state = { loading: false, pricePrediction: [] },
    action
) => {
    switch (action.type) {
        case GET_PRICE_DJUBLI_REQUEST:
            return { loading: true, pricePrediction: [] }
        case GET_PRICE_DJUBLI_SUCCESS:
            return { loading: false, pricePrediction: action.payload }
        case GET_PRICE_DJUBLI_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}