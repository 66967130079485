import React from 'react'
// MATERIAL UI
import { CircularProgress } from '@mui/material'

const LoadingComponent = ({ height = "100%" }) => {
  return (
    <div style={{ 
            height: height,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
    }}>
        <CircularProgress color="success" />
    </div>
  )
}

export default LoadingComponent