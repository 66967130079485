import {
    PREMIUM_CARS_REQUEST,
    PREMIUM_CARS_LIST_SUCCESS,
    PREMIUM_CARS_LIST_FAIL,
    INSPEKSI_CARS_REQUEST,
    INSPEKSI_CARS_LIST_SUCCESS,
    INSPEKSI_CARS_LIST_FAIL,
    QUERY_CARS_REQUEST,
    QUERY_CARS_LIST_SUCCESS,
    QUERY_CARS_LIST_FAIL,
    USER_CARS_REQUEST,
    USER_CARS_LIST_SUCCESS,
    USER_CARS_LIST_FAIL,
    LOW_KM_REQUEST,
    LOW_KM_LIST_SUCCESS,
    LOW_KM_LIST_FAIL,
    USER_CARS_LIST_RESET,
    DELETE_LISTING_CAR_REQUEST,
    DELETE_LISTING_CAR_SUCCESS,
    DELETE_LISTING_CAR_FAIL,
    PURCHASES_LIST_SUCCESS,
    PURCHASES_LIST_FAIL,
    PURCHASES_LIST_REQUEST,
    PURCHASES_LIST_RESET,
    DELETE_LISTING_CAR_RESET,
    GET_CATEGORIES_REQUEST,
    GET_CATEGORIES_SUCCESS,
    GET_CATEGORIES_FAIL,
    CAR_HERO_REQUEST,
    CAR_HERO_SUCCESS,
    CAR_HERO_FAIL,
    CAR_HERO_RESET,
    SELECT_HERO_CATEGORY,
    SWITCH_CATEGORIES_SUCCESS,
    GET_AUCTION_CARS_CAROUSEL_REQUEST,
    GET_AUCTION_CARS_CAROUSEL_SUCCESS,
    GET_AUCTION_CARS_CAROUSEL_FAIL
} from '../constants/carPerCategoryConstant'

export const categoriesReducer = (
    state = { loading: true, categories: null },
    action
) => {
    switch (action.type) {
        case GET_CATEGORIES_REQUEST:
            return { loading: true, categories: null }
        case GET_CATEGORIES_SUCCESS:
            return { loading: false, categories: action.payload, success: true }
        case SWITCH_CATEGORIES_SUCCESS:
            return { loading: false, categories: action.payload }
        case GET_CATEGORIES_FAIL:
            return { loading: false, error: [action.payload] }
        default:
            return state
    }
}

export const premiumCarListReducer = (
    state = { loading: true, premiumCars: [] },
    action
) => {
    switch (action.type) {
        case PREMIUM_CARS_REQUEST:
            return { loading: true, premiumCars: [] }
        case PREMIUM_CARS_LIST_SUCCESS:
            return { loading: false, premiumCars: action.payload }
        case PREMIUM_CARS_LIST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const inspeksiCarListReducer = (
    state = { loading: true, inspeksiCars: [] },
    action
) => {
    switch (action.type) {
        case INSPEKSI_CARS_REQUEST:
            return { loading: true, inspeksiCars: [] }
        case INSPEKSI_CARS_LIST_SUCCESS:
            return { loading: false, inspeksiCars: action.payload }
        case INSPEKSI_CARS_LIST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const lowKmCarListReducer = (
    state = { loading: true, lowKmCars: [] },
    action
) => {
    switch(action.type) {
        case LOW_KM_REQUEST: 
            return { loading: true, lowKmCars: [] }
        case LOW_KM_LIST_SUCCESS:
            return { loading: false, lowKmCars: action.payload }
        case LOW_KM_LIST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const queryCarListReducer = (
    state = { loading: true, queryCars: [] },
    action
) => {
    switch (action.type) {
        case QUERY_CARS_REQUEST:
            return { loading: true, queryCars: [] }
        case QUERY_CARS_LIST_SUCCESS:
            return { loading: false, queryCars: action.payload }
        case QUERY_CARS_LIST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const userCarListReducer = (
    state = { userCars: [] },
    action
) => {
    switch (action.type) {
        case USER_CARS_REQUEST:
            return { loading: true, userCars: [] }
        case USER_CARS_LIST_SUCCESS:
            return { loading: false, userCars: action.payload }
        case USER_CARS_LIST_FAIL:
            return { loading: false, error: action.payload }
        case USER_CARS_LIST_RESET:
            return { userCars: [] }
        default:
            return state
    }
}

export const deleteCarFromListingReducer = (state = { deleteCars: null }, action) => {
    switch (action.type) {
        case DELETE_LISTING_CAR_REQUEST:
            return { loading: true }
        case DELETE_LISTING_CAR_SUCCESS:
            return { loading: false, deleteCars: action.payload }
        case DELETE_LISTING_CAR_FAIL:
            return { loading: false, error: action.payload }
        case DELETE_LISTING_CAR_RESET:
            return { deleteCars: null }
        default:
            return state
    }
}

export const carPurchasesReducer = (
    state = { carPurchasesList: [] },
    action
) => {
    switch (action.type) {
        case PURCHASES_LIST_REQUEST:
            return { loading: true }
        case PURCHASES_LIST_SUCCESS:
            return { loading: false, carPurchasesList: action.payload }
        case PURCHASES_LIST_FAIL:
            return { loading: false, error: action.payload }
        case PURCHASES_LIST_RESET:
            return { carPurchasesList: [] }
        default:
            return state
    }
}

export const carHeroReducer = (
    state = { loading: true, carHero: null },
    action
) => {
    switch (action.type) {
        case CAR_HERO_REQUEST:
            return { loading: true }
        case CAR_HERO_SUCCESS:
            return { loading: false, carHero: action.payload }
        case CAR_HERO_FAIL:
            return { loading: false, error: action.payload }
        case CAR_HERO_RESET:
            return { carHero: null }
        default:
            return state
    }
}

export const selectHeroCategoryReducer = (
    state = { selectedCategory: null },
    action
) => {
    switch (action.type) {
        case SELECT_HERO_CATEGORY:
            return { selectedCategory: action.payload }
        case CAR_HERO_RESET:
            return { selectedCategory: null }
        default:
            return state
    }
}

export const auctionCarListCarouselReducer = (
    state = { 
        loading: true,
        success: false,
        error: false,
        auctionCars: null
    },
    action
) => {
    switch (action.type) {
        case GET_AUCTION_CARS_CAROUSEL_REQUEST:
            return { loading: true, success: false, error: false, auctionCars: null }
        case GET_AUCTION_CARS_CAROUSEL_SUCCESS:
            return { loading: false, success: true, error: false, auctionCars: action.payload }
        case GET_AUCTION_CARS_CAROUSEL_FAIL:
            return { loading: false, success: false, error: action.payload, auctionCars: null }
        default:
            return state
    }
}