import { Typography } from "@mui/material"

export const GET_VARIANT_FOREIGN_REQUEST = 'GET_VARIANT_FOREIGN_REQUEST'
export const GET_VARIANT_FOREIGN_SUCCESS = 'GET_VARIANT_FOREIGN_SUCCESS'
export const GET_VARIANT_FOREIGN_FAIL = 'GET_VARIANT_FOREIGN_FAIL'
export const GET_VARIANT_FOREIGN_RESET = 'GET_VARIANT_FOREIGN_RESET'
export const UPDATE_VARIANT_FOREIGN_SECTION = 'UPDATE_VARIANT_FOREIGN_SECTION'
export const VARIANT_FOREIGN_STATUS_RESET = 'VARIANT_FOREIGN_STATUS_RESET'

export const GET_VARIANT_FOREIGN_WEB_REQUEST = 'GET_VARIANT_FOREIGN_WEB_REQUEST'
export const GET_VARIANT_FOREIGN_WEB_SUCCESS = 'GET_VARIANT_FOREIGN_WEB_SUCCESS'
export const GET_VARIANT_FOREIGN_WEB_FAIL = 'GET_VARIANT_FOREIGN_WEB_FAIL'
export const GET_VARIANT_FOREIGN_WEB_RESET = 'GET_VARIANT_FOREIGN_WEB_RESET'

export const SUBMIT_BPKB_REQUEST = 'SUBMIT_BPKB_REQUEST'
export const SUBMIT_BPKB_SUCCESS = 'SUBMIT_BPKB_SUCCESS'
export const SUBMIT_BPKB_FAIL = 'SUBMIT_BPKB_FAIL'
export const SUBMIT_BPKB_RESET = 'SUBMIT_BPKB_RESET'

export const UPLOAD_DOKUMEN_BPKB_REQUEST = 'UPLOAD_DOKUMEN_BPKB_REQUEST'
export const UPLOAD_DOKUMEN_BPKB_SUCCESS = 'UPLOAD_DOKUMEN_BPKB_SUCCESS'
export const UPLOAD_DOKUMEN_BPKB_FAIL = 'UPLOAD_DOKUMEN_BPKB_FAIL'
export const UPLOAD_DOKUMEN_BPKB_RESET = 'UPLOAD_DOKUMEN_BPKB_RESET'

export const GET_ALL_BANK_REQUEST = 'GET_ALL_BANK_REQUEST'
export const GET_ALL_BANK_SUCCESS = 'GET_ALL_BANK_SUCCESS'
export const GET_ALL_BANK_FAIL = 'GET_ALL_BANK_FAIL'
export const GET_ALL_BANK_RESET = 'GET_ALL_BANK_RESET'

export const GET_STATUS_SLIK_REQUEST = 'GET_STATUS_SLIK_REQUEST'
export const GET_STATUS_SLIK_SUCCESS = 'GET_STATUS_SLIK_SUCCESS'
export const GET_STATUS_SLIK_FAIL = 'GET_STATUS_SLIK_FAIL'
export const GET_STATUS_SLIK_RESET = 'GET_STATUS_SLIK_RESET'

export const POST_CEK_SLIK_REQUEST = 'POST_CEK_SLIK_REQUEST'
export const POST_CEK_SLIK_SUCCESS = 'POST_CEK_SLIK_SUCCESS'
export const POST_CEK_SLIK_FAIL = 'POST_CEK_SLIK_FAIL'
export const POST_CEK_SLIK_RESET = 'POST_CEK_SLIK_RESET'

export const POST_EVENT_REQUEST = 'POST_EVENT_REQUEST'
export const POST_EVENT_SUCCESS = 'POST_EVENT_SUCCESS'
export const POST_EVENT_FAIL = 'POST_EVENT_FAIL'
export const POST_EVENT_RESET = 'POST_EVENT_RESET'

export const POST_SIMULATION_REQUEST = 'POST_SIMULATION_REQUEST'
export const POST_SIMULATION_SUCCESS = 'POST_SIMULATION_SUCCESS'
export const POST_SIMULATION_FAIL = 'POST_SIMULATION_FAIL'
export const POST_SIMULATION_RESET = 'POST_SIMULATION_RESET'

export const GET_ESTIMATION_SIM_REQUEST = 'GET_ESTIMATION_SIM_REQUEST'
export const GET_ESTIMATION_SIM_SUCCESS = 'GET_ESTIMATION_SIM_SUCCESS'
export const GET_ESTIMATION_SIM_FAIL = 'GET_ESTIMATION_SIM_FAIL'
export const GET_ESTIMATION_SIM_RESET = 'GET_ESTIMATION_SIM_RESET'

// SECTION
export const TDP_RINGAN_PROMO = 'TDP_RINGAN_PROMO'

// MENU FAQ
export const danaTunaiFAQ = [
    { 
      question: "Apa itu Dana Tunai di DjuBli?", 
      answer: "Dana Tunai DjuBli atau biasa disebut Gadai BPKB Mobil adalah produk pembiayaan dengan menggunakan jaminan (agunan) BPKB mobil sebagai syarat peminjamannya. Persyaratan untuk mengajukan Dana Tunai dari DjuBli memerlukan dokumen BPKB mobil mulai dari tahun 2010 sebagai persyaratan pengajuan. Menjadikan BPKB mobil sebagai jaminan untuk mendapatkan pinjaman dana bukanlah hal yang tabu di kota besar seperti Jakarta dengan jumlah pengguna mobil yang meningkat setiap tahunnya. Syarat dan ketentuan mengajukan Dana Tunai di DjuBli sangat mudah dan fleksibel. Selain menawarkan bunga yang rendah mulai dari 1% per bulan, proses pencairan dana pun hanya membutuhkan waktu 3 hari kerja dengan tenor pengembalian pinjaman hingga 4 tahun. Di DjuBli, Dana Tunai ini diperuntukkan untuk para calon peminjam yang ingin mendapatkan dana tunai mulai dari Rp 20 juta dengan jaminan BPKB mobil dan tenor pelunasan selama 5 tahun. Dengan memenuhi persyaratan yang ditentukan, calon peminjam bisa mendapatkan pinjaman dana dengan proses yang mudah dan cepat, tanpa ada biaya tambahan yang dibutuhkan saat pengajuan.",
      customElementAnswer: false
    },
    { 
      question: "Apa saja produk pembiayaan di DjuBli ?", 
      answer: <>
        <Typography>
            <strong>Dana Tunai (Gadai BPKB) Mobil</strong>
        </Typography>
        <Typography align='justify'>
            Dana Tunai atau Gadai BPKB Mobil mulai dari 20 juta dengan jaminan (agunan) BPKB mobil mulai dari tahun 2004 sebagai persyaratan peminjaman. Dana Tunai dengan jaminan BPKB mobil menawarkan tenor pelunasan selama maksimal selama 60 bulan (5 tahun).
        </Typography>
      </>,
      customElementAnswer: true
    },
    { 
      question: "Apa keunggulan mengajukan dana tunai di DjuBli ?", 
      answer: <>
        <Typography>
            Dengan mengajukan Dana Tunai atau Gadai BPKB Mobil di DjuBli, Anda akan mendapatkan keuntungan-keuntungan sebagai berikut:
        </Typography>
        <ul style={{ paddingLeft: 16 }}>
            <li style={{ paddingBottom: 4 }}>Mendapatkan Dana Tunai mulai dari 20 juta dengan jaminan BPKB mobil</li>
            <li style={{ paddingBottom: 4 }}>Bunga pinjaman rendah mulai dari 1% per Bulan</li>
            <li style={{ paddingBottom: 4 }}>Proses pengajuan dan pencairan dana yang relatif singkat, hanya 3 hari kerja</li>
            <li style={{ paddingBottom: 4 }}>Tenor pengembalian dana pinjaman cukup panjang (hingga 4 tahun)</li>
            <li style={{ paddingBottom: 4 }}>Syarat pinjaman tidak sulit dan fleksibel</li>
        </ul>
      </>,
      customElementAnswer: true
    },
    { 
      question: "Siapa saja yang dapat mengajukan Dana Tunai atau Gadai BPKB Mobil di DjuBli ?", 
      answer: "Konsumen perorangan dan manajemen perusahaan dapat mengajukan Dana Tunai atau Gadai BPKB Mobil untuk kebutuhan pribadi maupun perusahaan. Anda juga dapat mengajukan Dana Tunai menggunakan BPKB atas nama orang lain, BPKB atas nama perusahaan, dan kendaraan dengan pajak mati.",
      customElementAnswer: false
    },
    { 
      question: "Apa saja syarat pengajuan Dana Tunai atau Gadai BPKB Mobil di DjuBli ?", 
      answer: <>
        <Typography>
            Syarat dan ketentuan pengajuan Dana Tunai atau Gadai BPKB Mobil di DjuBli:
        </Typography>

        <ul style={{ paddingLeft: 16 }}>
            <li style={{ paddingBottom: 4 }}>Calon peminjam merupakan Warga Negara Indonesia (WNI)</li>
            <li style={{ paddingBottom: 4 }}>Usia pemohon harus di atas 21 tahun</li>
            <li style={{ paddingBottom: 4 }}>Usia pemohon dibawah 55-60 tahun pada saat masa pelunasan</li>
        </ul>
      </>,
      customElementAnswer: true
    },
    { 
      question: "Dokumen apa saja yang dibutuhkan untuk mengajukan Dana Tunai atau Gadai BPKB Mobil?", 
      answer: <>
        <Typography variant='body1' component='h6'>
            Sebagai syarat pengajuan Dana Tunai atau Gadai BPKB Mobil, Anda wajib melengkapi dan melampirkan dokumen berikut:
        </Typography>

        <ul style={{ paddingLeft: 16 }}>
            <li style={{ paddingBottom: 4 }}>Fotokopi Kartu Tanda Penduduk (KTP)</li>
            <li style={{ paddingBottom: 4 }}>Fotokopi Kartu Keluarga (KK)</li>
            <li style={{ paddingBottom: 4 }}>Slip gaji bulan terakhir / Surat Keterangan Gaji</li>
            <li style={{ paddingBottom: 4 }}>Dokumen / surat bukti kepemilikan kendaraan bermotor (BPKB)</li>
        </ul>
      </>,
      customElementAnswer: true
    },
    { 
      question: "Apakah bisa mengajukan Dana Tunai atau Gadai BPKB Mobil jika alamat domisili berbeda dengan KTP ?", 
      answer: "Jika alamat domisili Anda sekarang berbeda dengan alamat yang tertera di Kartu Tanda Penduduk (KTP), pengajuan Dana Tunai atau Gadai BPKB Mobil bisa tetap diproses dengan melampirkan Surat Keterangan Domisili ataupun Surat Keterangan Kerja.",
      customElementAnswer: false
    },
    { 
      question: "Berapa suku bunga Dana Tunai atau Gadai BPKB Mobil di DjuBli ?", 
      answer: "Penghitungan bunga untuk Dana Tunai atau Gadai BPKB Mobil DjuBli relatif rendah, mulai dari 1% per bulan. Besaran bunga dihitung dari persentase bunga dikalikan pokok pinjaman awal sehingga nilai bunga pinjaman akan sama setiap bulannya. Jadi, nominal angsuran yang perlu Anda bayarkan akan sama setiap bulannya.",
      customElementAnswer: false
    },
    { 
      question: "Apakah ada biaya tambahan untuk proses pengajuan Dana Tunai atau Gadai BPKB Mobil di DjuBli ?", 
      answer: "Jika Anda mengajukan Dana Tunai atau Gadai BPKB Mobil di DjuBli, Anda tidak akan dikenakan biaya-biaya lain. Anda tidak perlu membayar biaya administrasi, biaya, materai, dan lain-lain. Cukup melengkapi syarat dan ketentuan, maka Anda sudah bisa mendapatkan dana tunai untuk memenuhi kebutuhan Anda.",
      customElementAnswer: false
    },
    { 
      question: "Bagaimana cara pembayaran angsuran Gadai BPKB Mobil di DjuBli ?", 
      answer: <>
        <Typography>
            Pembayaran cicilan Dana Tunai atau Gadai BPKB Mobil DjuBli dilakukan secara bulanan. DjuBli menawarkan berbagai cara pembayaran cicilan bulanan untuk memudahkan para peminjam untuk melunasi pinjamannya, berikut caranya:
        </Typography>

        <ul style={{ paddingLeft: 16 }}>
            <li style={{ paddingBottom: 4 }}>Dengan metode transfer antarbank melalui ATM</li>
            <li style={{ paddingBottom: 4 }}>Dengan metode transfer menggunakan layanan internet banking dan mobile banking</li>
            <li style={{ paddingBottom: 4 }}>Dengan melalui layanan transaksi payment point lainnya</li>
        </ul>
      </>,
      customElementAnswer: true
    },
    { 
      question: "Berapa besaran denda jika terlambat membayar cicilan Gadai BPKB Mobil ?", 
      answer: "Jika peminjam terlambat melakukan pembayaran cicilan sesuai tanggal jatuh tempo, peminjam akan dikenai denda sebesar 0,5% dari angsuran per hari. Contoh, jika besaran angsuran adalah Rp1.000.000 maka denda keterlambatannya adalah 0,5% dari Rp1.000.000, yaitu Rp5.000.",
      customElementAnswer: false
    },
    { 
      question: "Apa jaminan (agunan) Dana Tunai atau Gadai BPKB Mobil DjuBli ?", 
      answer: "Jaminan atau agunan untuk mendapatkan dana tunai di DjuBli adalah BPKB mobil semua merek.",
      customElementAnswer: false
    },
    { 
      question: "Apakah program Dana Tunai atau Gadai BPKB Mobil ini berlaku juga untuk mobil yang masih dalam pinjaman ?", 
      answer: "Bisa. Kami bisa ambil alih / take over pinjaman nya. Hal ini juga sering disebut dengan refinancing",
      customElementAnswer: false
    },
    { 
      question: "Apa faedah pinjaman kami di ambil alih/take over dengan cara refinancing tersebut ?", 
      answer: "Suku bunga cenderung turun dalam beberapa tahun ini. Refinancing melalui DjuBliKredit ini bunganya lebih rendah sehingga anda akan mendapatkan dana tunai dan angsuran yang lebih rendah. Hal ini berlaku khususnya apabila pinjaman yang ada telah berjalan lebih dari separuh jangka waktunya.",
      customElementAnswer: false
    },
]

// RUMUS SIMULASI
export const installment = ({ creditAmount, creditRate, tenor }) => {
    // Rumus
    //
    // (1 + (Bunga * Tenor)) * PH
    //----------------------------
    //       Tenor * 12

    // console.log(`((1 + (${tenor} * ${creditRate} / 100)) * ${creditAmount}) / (${tenor} * 12) = ${((1 + (tenor * creditRate / 100)) * creditAmount) / (tenor * 12)}`)
    return ((1 + (tenor * creditRate / 100)) * creditAmount) / (tenor * 12);
}

export const creditAmount = ({ mrp, liquidationPrec }) => {
    // Rumus
    // Default
    // console.log(`${mrp} * ${liquidationPrec} = ${mrp * liquidationPrec}`);
    const result = mrp * liquidationPrec;
    return result;
}

export const liquidation = ({ mrp, adminFee, liquidation, insurance, isADDM, firstInstallment }) => {
    // Rumus
    // (mrp * presentase) - asuransi - admin - (angsuran pertama jika ADDM)
    // Atas Nama Pribadi = 90
    // Atas Nama Orang Lain = 75


    // console.log(`${(mrp * liquidation)} - ${insurance} - ${adminFee} - ${(isADDM ? firstInstallment : 0)} = ${ (mrp * liquidation) - insurance - adminFee - (isADDM ? firstInstallment : 0) }`);
    const result = (mrp * liquidation) - insurance - adminFee - (isADDM ? firstInstallment : 0);
    return result;
}

export const liquidationPrec = ({ mrp, adminFee, liquidation, insurance, isADDM, firstInstallment }) => {
    const result = ((liquidation + insurance + adminFee + (isADDM ? firstInstallment ?? 0 : 0)) / mrp) * 100;
    return result;
}

export const minLiquidationPercentage = ({ mrp, adminFee, insurance, isADDM, firstInstallment }) => {
    const twentyMil = 20000000;
    // console.log('hasil = ( $twentyMil +` $insurance + $adminFee + ${(isADDM ? firstInstallment : 0)} / $mrp}');
    // Pada akhirnya di kali seratus karena pada calculate akan dibagi 100 (sesuai format DB)
    return ((twentyMil + insurance + adminFee + (isADDM ? firstInstallment : 0)) / mrp) * 100;
}