import { 
    CAR_BID_PRICE_RESET,
    GET_ALL_AUCTION_SCHEDULE_FAIL,
    GET_ALL_AUCTION_SCHEDULE_REQUEST,
    GET_ALL_AUCTION_SCHEDULE_SUCCESS,
    GET_AUCTION_DETAIL_FAIL,
    GET_AUCTION_DETAIL_REQUEST,
    GET_AUCTION_DETAIL_SUCCESS,
    GET_AUCTION_HALL_DETAIL_FAIL,
    GET_AUCTION_HALL_DETAIL_REQUEST,
    GET_AUCTION_HALL_DETAIL_SUCCESS,
    GET_CAR_BID_FAIL,
    GET_CAR_BID_REQUEST,
    GET_CAR_BID_RESET,
    GET_CAR_BID_SUCCESS,
    GET_ONLINE_AUCTION_CAROUSEL_FAIL,
    GET_ONLINE_AUCTION_CAROUSEL_REQUEST,
    GET_ONLINE_AUCTION_CAROUSEL_SUCCESS,
    GET_ONSITE_AUCTION_CAROUSEL_FAIL,
    GET_ONSITE_AUCTION_CAROUSEL_REQUEST,
    GET_ONSITE_AUCTION_CAROUSEL_SUCCESS,
    UPDATE_CAR_BID,
    UPDATE_CAR_BID_LOADING,
    UPDATE_CAR_BID_PRICE
} from "../constants/auctionConstants"

export const auctionScheduleReducer = (
    state = { 
        loading: false,
        error: false,
        success: false,
        auctionScheduleList: [],
    },
    action
) => {
    switch (action.type) {
        case GET_ALL_AUCTION_SCHEDULE_REQUEST:
            return { 
                loading: true,
                error: false,
                success: false,
                auctionScheduleList: [] 
            }
        case GET_ALL_AUCTION_SCHEDULE_SUCCESS:
            return { 
                loading: false,
                error: false,
                success: true,
                auctionScheduleList: action.payload 
            }
        case GET_ALL_AUCTION_SCHEDULE_FAIL:
            return { 
                loading: false,
                error: action.payload,
                success: false,
                auctionScheduleList: []
            }
        default:
            return state
    }
}

export const onlineAuctionScheduleCarouselReducer = (
    state = { 
        loading: false,
        error: false,
        success: false,
        auctionScheduleList: [],
    },
    action
) => {
    switch (action.type) {
        case GET_ONLINE_AUCTION_CAROUSEL_REQUEST:
            return { 
                loading: true,
                error: false,
                success: false,
                auctionScheduleList: [] 
            }
        case GET_ONLINE_AUCTION_CAROUSEL_SUCCESS:
            return { 
                loading: false,
                error: false,
                success: true,
                auctionScheduleList: action.payload 
            }
        case GET_ONLINE_AUCTION_CAROUSEL_FAIL:
            return { 
                loading: false,
                error: action.payload,
                success: false,
                auctionScheduleList: []
            }
        default:
            return state
    }
}

export const onsiteAuctionScheduleCarouselReducer = (
    state = { 
        loading: false,
        error: false,
        success: false,
        auctionScheduleList: [],
    },
    action
) => {
    switch (action.type) {
        case GET_ONSITE_AUCTION_CAROUSEL_REQUEST:
            return { 
                loading: true,
                error: false,
                success: false,
                auctionScheduleList: [] 
            }
        case GET_ONSITE_AUCTION_CAROUSEL_SUCCESS:
            return { 
                loading: false,
                error: false,
                success: true,
                auctionScheduleList: action.payload 
            }
        case GET_ONSITE_AUCTION_CAROUSEL_FAIL:
            return { 
                loading: false,
                error: action.payload,
                success: false,
                auctionScheduleList: []
            }
        default:
            return state
    }
}

export const auctionDetailReducer = (
    state = { 
        loading: false,
        error: false,
        success: false,
        auctionDetail: [],
    },
    action
) => {
    switch (action.type) {
        case GET_AUCTION_DETAIL_REQUEST:
            return { 
                loading: true,
                error: false,
                success: false,
                auctionDetail: [] 
            }
        case GET_AUCTION_DETAIL_SUCCESS:
            return { 
                loading: false,
                error: false,
                success: true,
                auctionDetail: action.payload 
            }
        case GET_AUCTION_DETAIL_FAIL:
            return { 
                loading: false,
                error: action.payload,
                success: false,
                auctionDetail: []
            }
        default:
            return state
    }
}

export const auctionHallDetailReducer = (
    state = { 
        loading: false,
        error: false,
        success: false,
        auctionHall: [],
    },
    action
) => {
    switch (action.type) {
        case GET_AUCTION_HALL_DETAIL_REQUEST:
            return { 
                loading: true,
                error: false,
                success: false,
                auctionHall: null 
            }
        case GET_AUCTION_HALL_DETAIL_SUCCESS:
            return { 
                loading: false,
                error: false,
                success: true,
                auctionHall: action.payload 
            }
        case GET_AUCTION_HALL_DETAIL_FAIL:
            return { 
                loading: false,
                error: action.payload,
                success: false,
                auctionHall: null
            }
        default:
            return state
    }
}

export const auctionBidListReducer = (
    state = { 
        loading: false,
        error: false,
        success: false,
        bids: null,
    },
    action
) => {
    switch (action.type) {
        case GET_CAR_BID_REQUEST:
            return { 
                loading: true,
                error: false,
                success: false,
                bids: null 
            }
        case GET_CAR_BID_SUCCESS:
            return { 
                loading: false,
                error: false,
                success: true,
                bids: action.payload 
            }
        case GET_CAR_BID_FAIL:
            return { 
                loading: false,
                error: action.payload,
                success: false,
                bids: null
            }
        case UPDATE_CAR_BID:
            return { 
                loading: false,
                error: false,
                success: true,
                bids: [ action.payload, ...state.bids ]
            }
        case GET_CAR_BID_RESET:
            return { 
                loading: false,
                error: false,
                success: false,
                bids: null,
            }
        default:
            return state
    }
}

export const auctionHighestBidPriceReducer = (
    state = { loading: false, bidPrice: null },
    action
) => {
    switch (action.type) {
        case UPDATE_CAR_BID_LOADING:
            return { loading: true, bidPrice: null }
        case UPDATE_CAR_BID_PRICE:
            return { loading: false, bidPrice: action.payload }
        case CAR_BID_PRICE_RESET:
            return { loading: false, bidPrice: null }
        default:
            return state
    }
}