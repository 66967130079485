export const GET_MARKET_LINE_REQUEST = 'GET_MARKET_LINE_REQUEST'
export const GET_MARKET_LINE_SUCCESS = 'GET_MARKET_LINE_SUCCESS'
export const GET_MARKET_LINE_FAIL = 'GET_MARKET_LINE_FAIL'
export const GET_MARKET_LINE_RESET = 'GET_MARKET_LINE_RESET'

export const GET_MARKET_CARS_REQUEST = 'GET_MARKET_CARS_REQUEST'
export const GET_MARKET_CARS_SUCCESS = 'GET_MARKET_CARS_SUCCESS'
export const GET_MARKET_CARS_FAIL = 'GET_MARKET_CARS_FAIL'
export const GET_MARKET_CARS_RESET = 'GET_MARKET_CARS_RESET'

export const GET_MARKET_POPULAR_CAR_REQUEST = 'GET_MARKET_POPULAR_CAR_REQUEST'
export const GET_MARKET_POPULAR_CAR_SUCCESS = 'GET_MARKET_POPULAR_CAR_SUCCESS'
export const GET_MARKET_POPULAR_CAR_FAIL = 'GET_MARKET_POPULAR_CAR_FAIL'
export const GET_MARKET_POPULAR_CAR_RESET = 'GET_MARKET_POPULAR_CAR_RESET'