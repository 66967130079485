import { 
    GET_MARKET_LINE_FAIL,
    GET_MARKET_LINE_REQUEST,
    GET_MARKET_LINE_RESET,
    GET_MARKET_LINE_SUCCESS,
    GET_MARKET_POPULAR_CAR_FAIL,
    GET_MARKET_POPULAR_CAR_REQUEST,
    GET_MARKET_POPULAR_CAR_RESET,
    GET_MARKET_POPULAR_CAR_SUCCESS
} from "../constants/marketConstants"

export const marketLineDataReducer = (
    state = { 
        loading: false,
        success: false,
        error: false,
        data: null
    },
    action
) => {
    switch (action.type) {
        case GET_MARKET_LINE_REQUEST:
            return { loading: true, success: false, error: false, data: null }
        case GET_MARKET_LINE_SUCCESS:
            return { loading: false, success: true, error: false, data: action.payload }
        case GET_MARKET_LINE_FAIL:
            return { loading: false, success: false, error: action.payload, data: null }
        case GET_MARKET_LINE_RESET:
            return { loading: false, success: false, error: false, data: null }
        default:
            return state
    }
}

export const marketPopularCarsReducer = (
    state = { 
        loading: false,
        success: false,
        error: false,
        data: null
    },
    action
) => {
    switch (action.type) {
        case GET_MARKET_POPULAR_CAR_REQUEST:
            return { loading: true, success: false, error: false, data: null }
        case GET_MARKET_POPULAR_CAR_SUCCESS:
            return { loading: false, success: true, error: false, data: action.payload }
        case GET_MARKET_POPULAR_CAR_FAIL:
            return { loading: false, success: false, error: action.payload, data: null }
        case GET_MARKET_POPULAR_CAR_RESET:
            return { loading: false, success: false, error: false, data: null }
        default:
            return state
    }
}