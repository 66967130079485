export const ALL_DEALER_REQUEST = 'ALL_DEALER_REQUEST'
export const ALL_DEALER_SUCCESS = 'ALL_DEALER_SUCCESS'
export const ALL_DEALER_FAIL = 'ALL_DEALER_FAIL'
export const ALL_DEALER_RESET = 'ALL_DEALER_RESET'

export const MOST_LISTING_DEALER_REQUEST = 'MOST_LISTING_DEALER_REQUEST'
export const MOST_LISTING_DEALER_SUCCESS = 'MOST_LISTING_DEALER_SUCCESS'
export const MOST_LISTING_DEALER_FAIL = 'MOST_LISTING_DEALER_FAIL'
export const MOST_LISTING_DEALER_RESET = 'MOST_LISTING_DEALER_RESET'

export const DEALER_BY_NAME_REQUEST = 'DEALER_BY_NAME_REQUEST'
export const DEALER_BY_NAME_SUCCESS = 'DEALER_BY_NAME_SUCCESS'
export const DEALER_BY_NAME_FAIL = 'DEALER_BY_NAME_FAIL'
export const DEALER_BY_NAME_RESET = 'DEALER_BY_NAME_RESET'

export const DEALER_PER_CATEGORY_REQUEST = 'DEALER_PER_CATEGORY_REQUEST'
export const DEALER_PER_CATEGORY_SUCCESS = 'DEALER_PER_CATEGORY_SUCCESS'
export const DEALER_PER_CATEGORY_FAIL = 'DEALER_PER_CATEGORY_FAIL'
export const DEALER_PER_CATEGORY_RESET = 'DEALER_PER_CATEGORY_RESET'