import { 
    SUBMIT_CAIR_BPKB_REQUEST, 
    SUBMIT_CAIR_BPKB_SUCCESS,
    SUBMIT_CAIR_BPKB_FAIL,
    SUBMIT_CAIR_BPKB_RESET,

} from '../constants/cairBpkbConstant'

export const cairBpkbReducer = (
    state = { loading: false, formData: null },
    action
) => {
    switch (action.type) {
        case SUBMIT_CAIR_BPKB_REQUEST:
            return { loading: true, formData: null }
        case SUBMIT_CAIR_BPKB_SUCCESS:
            return { loading: false, formData: action.payload, error: false }
        case SUBMIT_CAIR_BPKB_FAIL:
            return { loading: false, error: action.payload }
        case SUBMIT_CAIR_BPKB_RESET:
            return { loading: false, formData: null }
        default:
            return state
    }
}