export const SELECT_EXTERIOR_COLOR_AT_FILTER = 'SELECT_EXTERIOR_COLOR_AT_FILTER'
export const RESET_EXTERIOR_COLOR_AT_FILTER = 'RESET_EXTERIOR_COLOR_AT_FILTER'

export const SELECT_INTERIOR_COLOR_AT_FILTER = 'SELECT_INTERIOR_COLOR_AT_FILTER'
export const RESET_INTERIOR_COLOR_AT_FILTER = 'RESET_INTERIOR_COLOR_AT_FILTER'

export const SELECT_CITY_AT_FILTER = 'SELECT_CITY_AT_FILTER'
export const RESET_CITY_AT_FILTER = 'RESET_CITY_AT_FILTER'

export const SELECT_SUBDISTRICT_AT_FILTER = 'SELECT_SUBDISTRICT_AT_FILTER'
export const RESET_SUBDISTRICT_AT_FILTER = 'RESET_SUBDISTRICT_AT_FILTER'

export const SELECT_CONDITION_AT_FILTER = 'SELECT_CONDITION_AT_FILTER'
export const RESET_CONDITION_AT_FILTER = 'RESET_CONDITION_AT_FILTER'

export const SELECT_RANGE_TAHUN_AT_FILTER = 'SELECT_RANGE_TAHUN_AT_FILTER'
export const RESET_RANGE_TAHUN_AT_FILTER = 'RESET_RANGE_TAHUN_AT_FILTER'

export const SELECT_RANGE_HARGA_AT_FILTER = 'SELECT_RANGE_HARGA_AT_FILTER'
export const RESET_RANGE_HARGA_AT_FILTER = 'RESET_RANGE_HARGA_AT_FILTER'

export const SELECT_RANGE_KILOMETER_AT_FILTER = 'SELECT_RANGE_KILOMETER_AT_FILTER'
export const RESET_RANGE_KILOMETER_AT_FILTER = 'RESET_RANGE_KILOMETER_AT_FILTER'