import { 
    GET_ALL_BANK_FAIL,
    GET_ALL_BANK_REQUEST,
    GET_ALL_BANK_RESET,
    GET_ALL_BANK_SUCCESS,
    GET_ESTIMATION_SIM_FAIL,
    GET_ESTIMATION_SIM_REQUEST,
    GET_ESTIMATION_SIM_RESET,
    GET_ESTIMATION_SIM_SUCCESS,
    GET_STATUS_SLIK_FAIL,
    GET_STATUS_SLIK_REQUEST,
    GET_STATUS_SLIK_RESET,
    GET_STATUS_SLIK_SUCCESS,
    GET_VARIANT_FOREIGN_FAIL,
    GET_VARIANT_FOREIGN_REQUEST,
    GET_VARIANT_FOREIGN_RESET,
    GET_VARIANT_FOREIGN_SUCCESS,
    GET_VARIANT_FOREIGN_WEB_FAIL,
    GET_VARIANT_FOREIGN_WEB_REQUEST,
    GET_VARIANT_FOREIGN_WEB_RESET,
    GET_VARIANT_FOREIGN_WEB_SUCCESS,
    POST_CEK_SLIK_FAIL,
    POST_CEK_SLIK_REQUEST,
    POST_CEK_SLIK_RESET,
    POST_CEK_SLIK_SUCCESS,
    SUBMIT_BPKB_FAIL,
    SUBMIT_BPKB_REQUEST,
    SUBMIT_BPKB_RESET,
    SUBMIT_BPKB_SUCCESS,
    UPDATE_VARIANT_FOREIGN_SECTION,
    UPLOAD_DOKUMEN_BPKB_FAIL,
    UPLOAD_DOKUMEN_BPKB_REQUEST,
    UPLOAD_DOKUMEN_BPKB_RESET,
    UPLOAD_DOKUMEN_BPKB_SUCCESS,
    VARIANT_FOREIGN_STATUS_RESET
} from "../constants/danaTunaiConstant"

export const variantByForeignReducer = (
    state = { variant: [], section: null },
    action
) => {
    switch (action.type) {
        case GET_VARIANT_FOREIGN_REQUEST:
            return { ...state, error: false, loading: true, variant: [] }
        case GET_VARIANT_FOREIGN_SUCCESS:
            return { 
                ...state,
                loading: false,
                error: false,
                success: true,
                variant: action.payload
            }
        case VARIANT_FOREIGN_STATUS_RESET:
            return { ...state, success: false }
        case GET_VARIANT_FOREIGN_FAIL:
            return { ...state, loading: false, error: action.payload }
        case GET_VARIANT_FOREIGN_RESET:
            return { loading: false, variant: [], section: null }
        case UPDATE_VARIANT_FOREIGN_SECTION:
            return { ...state, section: action.payload }
        default:
            return state
    }
}

export const variantByForeignWebReducer = (
    state = { variant: [], section: null },
    action
) => {
    switch (action.type) {
        case GET_VARIANT_FOREIGN_WEB_REQUEST:
            return { ...state, error: false, loading: true, variant: [] }
        case GET_VARIANT_FOREIGN_WEB_SUCCESS:
            return { ...state, loading: false, error: false, success: true, variant: action.payload }
        case GET_VARIANT_FOREIGN_WEB_FAIL:
            return { ...state, loading: false, error: action.payload }
        case GET_VARIANT_FOREIGN_WEB_RESET:
            return { loading: false, variant: [], section: null }
        default:
            return state
    }
}

export const submitDanaTunaiReducer = (
    state = { submitDanaTunai: null },
    action
) => {
    switch (action.type) {
        case SUBMIT_BPKB_REQUEST:
            return { loading: true, submitDanaTunai: [] }
        case SUBMIT_BPKB_SUCCESS:
            return { loading: false, success: true, submitDanaTunai: action.payload }
        case SUBMIT_BPKB_FAIL:
            return { loading: false, error: action.payload }
        case SUBMIT_BPKB_RESET:
            return { loading: false, submitDanaTunai: [] }
        default:
            return state
    }
}

export const uploadDanaTunaiReducer = (
    state = { uploadDokumen: null },
    action
) => {
    switch (action.type) {
        case UPLOAD_DOKUMEN_BPKB_REQUEST:
            return { loading: true, uploadDokumen: [] }
        case UPLOAD_DOKUMEN_BPKB_SUCCESS:
            return { loading: false, success: true, uploadDokumen: action.payload }
        case UPLOAD_DOKUMEN_BPKB_FAIL:
            return { loading: false, error: action.payload }
        case UPLOAD_DOKUMEN_BPKB_RESET:
            return { loading: false, uploadDokumen: [] }
        default:
            return state
    }
}

export const bankListReducer = (
    state = { banks: null },
    action
) => {
    switch (action.type) {
        case GET_ALL_BANK_REQUEST:
            return { loading: true, banks: null }
        case GET_ALL_BANK_SUCCESS:
            return { loading: false, banks: action.payload }
        case GET_ALL_BANK_FAIL:
            return { loading: false, error: action.payload }
        case GET_ALL_BANK_RESET:
            return { loading: false, banks: null }
        default:
            return state
    }
}

export const submitCekSlikReducer = ( 
    state = { cekSlik: null },
    action 
) => {
    switch (action.type) {
        case POST_CEK_SLIK_REQUEST:
            return { loading: true, cekSlik: null }
        case POST_CEK_SLIK_SUCCESS:
            return { loading: false, cekSlik: action.payload }
        case POST_CEK_SLIK_FAIL:
            return { loading: false, error: action.payload }
        case POST_CEK_SLIK_RESET:
            return { loading: false, cekSlik: null }
        default:
            return state
    }
}

export const getSlikStatusReducer = (
    state = { userSlik: null },
    action
) => {
    switch (action.type) {
        case GET_STATUS_SLIK_REQUEST:
            return { loading: true, userSlik: null }
        case GET_STATUS_SLIK_SUCCESS:
            return { loading: false, userSlik: action.payload }
        case GET_STATUS_SLIK_FAIL:
            return { loading: false, error: action.payload }
        case GET_STATUS_SLIK_RESET:
            return { loading: false, userSlik: null }
        default:
            return state
    }
}

export const estimationSimulationReducer = (
    state = { 
        estimation: null,
        loading: false,
        success: false,
        error: false
    },
    action
) => {
    switch (action.type) {
        case GET_ESTIMATION_SIM_REQUEST:
            return { loading: true, error: false, estimation: null, succes: false }
        case GET_ESTIMATION_SIM_SUCCESS:
            return { success: true, loading: false, error: false, estimation: action.payload }
        case GET_ESTIMATION_SIM_FAIL:
            return { loading: false, error: action.payload, estimation: null, succes: false }
        case GET_ESTIMATION_SIM_RESET:
            return { estimation: null, loading: false, success: false, error: false }
        default:
            return state
    }
}