export const GET_PROMO_CAR_LIST_REQUEST = 'GET_PROMO_CAR_LIST_REQUEST'
export const GET_PROMO_CAR_LIST_SUCCESS = 'GET_PROMO_CAR_LIST_SUCCESS'
export const GET_PROMO_CAR_LIST_FAIL = 'GET_PROMO_CAR_LIST_FAIL'
export const GET_PROMO_CAR_LIST_RESET = 'GET_PROMO_CAR_LIST_RESET'

export const GET_PROMO_BANNER_REQUEST = 'GET_PROMO_BANNER_REQUEST'
export const GET_PROMO_BANNER_SUCCESS = 'GET_PROMO_BANNER_SUCCESS'
export const GET_PROMO_BANNER_FAIL = 'GET_PROMO_BANNER_FAIL'
export const GET_PROMO_BANNER_RESET = 'GET_PROMO_BANNER_RESET'

export const GET_PROMO_BY_MYID_REQUEST = 'GET_PROMO_BY_MYID_REQUEST'
export const GET_PROMO_BY_MYID_SUCCESS = 'GET_PROMO_BY_MYID_SUCCESS'
export const GET_PROMO_BY_MYID_FAIL = 'GET_PROMO_BY_MYID_FAIL'
export const GET_PROMO_BY_MYID_RESET = 'GET_PROMO_BY_MYID_RESET'

export const GET_OTHER_PROMO_CARS_REQUEST = 'GET_OTHER_PROMO_CARS_REQUEST'
export const GET_OTHER_PROMO_CARS_SUCCESS = 'GET_OTHER_PROMO_CARS_SUCCESS'
export const GET_OTHER_PROMO_CARS_FAIL = 'GET_OTHER_PROMO_CARS_FAIL'
export const GET_OTHER_PROMO_CARS_RESET = 'GET_OTHER_PROMO_CARS_RESET'

export const APPLY_CREDIT_KKB_REQUEST = 'APPLY_CREDIT_KKB_REQUEST'
export const APPLY_CREDIT_KKB_SUCCESS = 'APPLY_CREDIT_KKB_SUCCESS'
export const APPLY_CREDIT_KKB_FAIL = 'APPLY_CREDIT_KKB_FAIL'
export const APPLY_CREDIT_KKB_RESET = 'APPLY_CREDIT_KKB_RESET'

export const GET_OTHER_PLATFORM_REQUEST = 'GET_OTHER_PLATFORM_REQUEST'
export const GET_OTHER_PLATFORM_SUCCESS = 'GET_OTHER_PLATFORM_SUCCESS'
export const GET_OTHER_PLATFORM_FAIL = 'GET_OTHER_PLATFORM_FAIL'
export const GET_OTHER_PLATFORM_RESET = 'GET_OTHER_PLATFORM_RESET'

export const GET_KKB_ESTIMATION_REQUEST = 'GET_KKB_ESTIMATION_REQUEST'
export const GET_KKB_ESTIMATION_SUCCESS = 'GET_KKB_ESTIMATION_SUCCESS'
export const GET_KKB_ESTIMATION_FAIL = 'GET_KKB_ESTIMATION_FAIL'
export const GET_KKB_ESTIMATION_RESET = 'GET_KKB_ESTIMATION_RESET'