export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL'
export const USER_LOGIN_STATUS_RESET = 'USER_LOGIN_STATUS_RESET'
export const USER_LOGOUT = 'USER_LOGOUT'
export const USER_UPDATE_DATA_LOGIN = 'USER_UPDATE_DATA_LOGIN'

export const USER_LOGIN_NO_PASS_REQUEST = 'USER_LOGIN_NO_PASS_REQUEST'
export const USER_LOGIN_NO_PASS_SUCCESS = 'USER_LOGIN_NO_PASS_SUCCESS'
export const USER_LOGIN_NO_PASS_FAIL = 'USER_LOGIN_NO_PASS_FAIL'
export const USER_LOGIN_NO_PASS_RESET = 'USER_LOGIN_NO_PASS_RESET'

export const USER_LOGIN_OTP_REQUEST = 'USER_LOGIN_OTP_REQUEST'
export const USER_LOGIN_OTP_SUCCESS = 'USER_LOGIN_OTP_SUCCESS'
export const USER_LOGIN_OTP_FAIL = 'USER_LOGIN_OTP_FAIL'
export const USER_LOGIN_OTP_RESET = 'USER_LOGIN_OTP_RESET'

export const REGISTER_OTP_REQUEST = 'REGISTER_OTP_REQUEST'
export const REGISTER_OTP_SUCCESS = 'REGISTER_OTP_SUCCESS'
export const REGISTER_OTP_FAIL = 'REGISTER_OTP_FAIL'

export const VERIFY_OTP_REQUEST = 'VERIFY_OTP_REQUEST'
export const VERIFY_OTP_SUCCESS = 'VERIFY_OTP_SUCCESS'
export const VERIFY_OTP_FAIL = 'VERIFY_OTP_FAIL'
export const VERIFY_OTP_RESET = 'VERIFY_OTP_RESET'

export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST'
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS'
export const USER_UPDATE_FAIL = 'USER_UPDATE_FAIL'
export const USER_UPDATE_RESET = 'USER_UPDATE_RESET'

export const SUBMIT_FORM_USER_BUY_REQUEST = 'SUBMIT_FORM_USER_BUY_REQUEST'
export const SUBMIT_FORM_USER_BUY_SUCCESS = 'SUBMIT_FORM_USER_BUY_SUCCESS'
export const SUBMIT_FORM_USER_BUY_FAIL = 'SUBMIT_FORM_USER_BUY_FAIL'

export const USER_NO_LOGIN_REQUEST = 'USER_NO_LOGIN_REQUEST'
export const USER_NO_LOGIN_SUCCESS = 'USER_NO_LOGIN_SUCCESS'
export const USER_NO_LOGIN_FAIL = 'USER_NO_LOGIN_FAIL'

export const CHECK_REFERRAL_REQUEST = 'CHECK_REFERRAL_REQUEST'
export const CHECK_REFERRAL_SUCCESS = 'CHECK_REFERRAL_SUCCESS'
export const CHECK_REFERRAL_FAIL = 'CHECK_REFERRAL_FAIL'

export const GET_USER_LOCATION_REQUEST = 'GET_USER_LOCATION_REQUEST'
export const GET_USER_LOCATION_SUCCESS = 'GET_USER_LOCATION_SUCCESS'
export const GET_USER_LOCATION_FAIL = 'GET_USER_LOCATION_FAIL'

export const REGISTER_NO_PASSWORD_REQUEST = 'REGISTER_NO_PASSWORD_REQUEST'
export const REGISTER_NO_PASSWORD_SUCCESS = 'REGISTER_NO_PASSWORD_SUCCESS'
export const REGISTER_NO_PASSWORD_FAIL = 'REGISTER_NO_PASSWORD_FAIL'
export const REGISTER_NO_PASSWORD_STATUS_RESET = 'REGISTER_NO_PASSWORD_STATUS_RESET'

export const USER_LOGIN_AFTER_REGISTER_NO_PASS = 'LOGIN_AFTER_REGISTER_NO_PASS'
export const USER_LOGIN_AFTER_REGISTER_NO_PASS_LOADING = 'LOGIN_AFTER_REGISTER_NO_PASS_LOADING'
export const USER_LOGIN_AFTER_REGISTER_NO_PASS_FAIL = 'LOGIN_AFTER_REGISTER_NO_PASS_FAIL'

export const OTP_NO_PASSWORD_REQUEST = 'OTP_NO_PASSWORD_REQUEST'
export const OTP_NO_PASSWORD_SUCCESS = 'OTP_NO_PASSWORD_SUCCESS'
export const OTP_NO_PASSWORD_FAIL = 'OTP_NO_PASSWORD_FAIL'
export const OTP_NO_PASSWORD_STATUS_RESET = 'OTP_NO_PASSWORD_STATUS_RESET'

export const OTP_LOGIN_REQUEST = 'OTP_LOGIN_REQUEST'
export const OTP_LOGIN_SUCCESS = 'OTP_LOGIN_SUCCESS'
export const OTP_LOGIN_FAIL = 'OTP_LOGIN_FAIL'
export const OTP_LOGIN_STATUS_RESET = 'OTP_LOGIN_STATUS_RESET'

export const VERIFY_OTP_NO_PASSWORD_REQUEST = 'VERIFY_OTP_NO_PASSWORD_REQUEST'
export const VERIFY_OTP_NO_PASSWORD_SUCCESS = 'VERIFY_OTP_NO_PASSWORD_SUCCESS'
export const VERIFY_OTP_NO_PASSWORD_FAIL = 'VERIFY_OTP_NO_PASSWORD_FAIL'
export const VERIFY_OTP_NO_PASSWORD_STATUS_RESET = 'VERIFY_OTP_NO_PASSWORD_STATUS_RESET'

export const VERIFY_OTP_LOGIN_REQUEST = 'VERIFY_OTP_LOGIN_REQUEST'
export const VERIFY_OTP_LOGIN_SUCCESS = 'VERIFY_OTP_LOGIN_SUCCESS'
export const VERIFY_OTP_LOGIN_FAIL = 'VERIFY_OTP_LOGIN_FAIL'
export const VERIFY_OTP_LOGIN_STATUS_RESET = 'VERIFY_OTP_LOGIN_STATUS_RESET'

export const DELETE_ACCOUNT_REQUEST = 'DELETE_ACCOUNT_REQUEST'
export const DELETE_ACCOUNT_SUCCESS = 'DELETE_ACCOUNT_SUCCESS'
export const DELETE_ACCOUNT_FAIL = 'DELETE_ACCOUNT_FAIL'
export const DELETE_ACCOUNT_RESET = 'DELETE_ACCOUNT_RESET'

export const OTP_DELETE_ACCOUNT_REQUEST = 'OTP_DELETE_ACCOUNT_REQUEST'
export const OTP_DELETE_ACCOUNT_SUCCESS = 'OTP_DELETE_ACCOUNT_SUCCESS'
export const OTP_DELETE_ACCOUNT_FAIL = 'OTP_DELETE_ACCOUNT_FAIL'
export const OTP_DELETE_ACCOUNT_RESET = 'OTP_DELETE_ACCOUNT_RESET'

export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST'
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS'
export const CHANGE_PASSWORD_FAIL = 'CHANGE_PASSWORD_FAIL'
export const CHANGE_PASSWORD_RESET = 'CHANGE_PASSWORD_RESET'

export const VERIFY_OTP_LEADS_REQUEST = 'VERIFY_OTP_LEADS_REQUEST'
export const VERIFY_OTP_LEADS_SUCCESS = 'VERIFY_OTP_LEADS_SUCCESS'
export const VERIFY_OTP_LEADS_FAIL = 'VERIFY_OTP_LEADS_FAIL'
export const VERIFY_OTP_LEADS_RESET = 'VERIFY_OTP_LEADS_RESET'