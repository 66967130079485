export const SELECT_MERK_AT_SELL_CAR = 'SELECT_MERK_AT_SELL_CAR'
export const SELECT_GROUP_MODEL_AT_SELL_CAR = 'SELECT_GROUP_MODEL_AT_SELL_CAR'
export const SELECT_MODEL_AT_SELL_CAR = 'SELECT_MODEL_AT_SELL_CAR'
export const SELECT_MODEL_YEAR_AT_SELL_CAR = 'SELECT_MODEL_YEAR_AT_SELL_CAR'
export const SELECT_YEAR_OF_USAGE_AT_SELL_CAR = 'SELECT_YEAR_OF_USAGE_AT_SELL_CAR'
export const SELECT_EXTERIOR_COLOR_AT_SELL_CAR = 'SELECT_EXTERIOR_COLOR_AT_SELL_CAR'
export const SELECT_INTERIOR_COLOR_AT_SELL_CAR = 'SELECT_INTERIOR_COLOR_AT_SELL_CAR'
export const SET_KILOMETER_VALUE_AT_SELL_CAR = 'SET_KILOMETER_VALUE_AT_SELL_CAR'
export const SET_PHONE_NUMBER_VALUE_AT_SELL_CAR = 'SET_PHONE_NUMBER_VALUE_AT_SELL_CAR'

export const RESET_MERK_AT_SELL_CAR = 'RESET_MERK_AT_SELL_CAR'
export const RESET_GROUP_MODEL_AT_SELL_CAR = 'RESET_GROUP_MODEL_AT_SELL_CAR'
export const RESET_MODEL_AT_SELL_CAR = 'RESET_MODEL_AT_SELL_CAR'
export const RESET_MODEL_YEAR_AT_SELL_CAR = 'RESET_MODEL_YEAR_AT_SELL_CAR'
export const RESET_YEAR_OF_USAGE_AT_SELL_CAR = 'RESET_YEAR_OF_USAGE_AT_SELL_CAR'
export const RESET_ALL_VALUE_FORM_AT_SELL_CAR = 'RESET_ALL_VALUE_FORM_AT_SELL_CAR'

export const GET_PRICE_DJUBLI_REQUEST = 'GET_PRICE_DJUBLI_REQUEST'
export const GET_PRICE_DJUBLI_SUCCESS = 'GET_PRICE_DJUBLI_SUCCESS'
export const GET_PRICE_DJUBLI_FAIL = 'GET_PRICE_DJUBLI_FAIL'
export const GET_PRICE_DJUBLI_RESET = 'GET_PRICE_DJUBLI_RESET'