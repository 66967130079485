// SECTION
export const DJUBLI_LELANG_SECTION = 'DJUBLI_LELANG_SECTION'

export const GET_ALL_AUCTION_SCHEDULE_REQUEST = 'GET_ALL_AUCTION_SCHEDULE_REQUEST'
export const GET_ALL_AUCTION_SCHEDULE_SUCCESS = 'GET_ALL_AUCTION_SCHEDULE_SUCCESS'
export const GET_ALL_AUCTION_SCHEDULE_FAIL = 'GET_ALL_AUCTION_SCHEDULE_FAIL'
export const GET_ALL_AUCTION_SCHEDULE_RESET = 'GET_ALL_AUCTION_SCHEDULE_RESET'

export const GET_ONLINE_AUCTION_CAROUSEL_REQUEST = 'GET_ONLINE_AUCTION_CAROUSEL_REQUEST'
export const GET_ONLINE_AUCTION_CAROUSEL_SUCCESS = 'GET_ONLINE_AUCTION_CAROUSEL_SUCCESS'
export const GET_ONLINE_AUCTION_CAROUSEL_FAIL = 'GET_ONLINE_AUCTION_CAROUSEL_FAIL'
export const GET_ONLINE_AUCTION_CAROUSEL_RESET = 'GET_ONLINE_AUCTION_CAROUSEL_RESET'

export const GET_ONSITE_AUCTION_CAROUSEL_REQUEST = 'GET_ONSITE_AUCTION_CAROUSEL_REQUEST'
export const GET_ONSITE_AUCTION_CAROUSEL_SUCCESS = 'GET_ONSITE_AUCTION_CAROUSEL_SUCCESS'
export const GET_ONSITE_AUCTION_CAROUSEL_FAIL = 'GET_ONSITE_AUCTION_CAROUSEL_FAIL'
export const GET_ONSITE_AUCTION_CAROUSEL_RESET = 'GET_ONSITE_AUCTION_CAROUSEL_RESET'

export const GET_AUCTION_DETAIL_REQUEST = 'GET_AUCTION_DETAIL_REQUEST'
export const GET_AUCTION_DETAIL_SUCCESS = 'GET_AUCTION_DETAIL_SUCCESS'
export const GET_AUCTION_DETAIL_FAIL = 'GET_AUCTION_DETAIL_FAIL'
export const GET_AUCTION_DETAIL_RESET = 'GET_AUCTION_DETAIL_RESET'

export const GET_AUCTION_HALL_DETAIL_REQUEST = 'GET_AUCTION_HALL_DETAIL_REQUEST'
export const GET_AUCTION_HALL_DETAIL_SUCCESS = 'GET_AUCTION_HALL_DETAIL_SUCCESS'
export const GET_AUCTION_HALL_DETAIL_FAIL = 'GET_AUCTION_HALL_DETAIL_FAIL'
export const GET_AUCTION_HALL_DETAIL_RESET = 'GET_AUCTION_HALL_DETAIL_RESET'

export const GET_CAR_BID_REQUEST = 'GET_CAR_BID_REQUEST'
export const GET_CAR_BID_SUCCESS = 'GET_CAR_BID_SUCCESS'
export const GET_CAR_BID_FAIL = 'GET_CAR_BID_FAIL'
export const GET_CAR_BID_RESET = 'GET_CAR_BID_RESET'

export const UPDATE_CAR_BID = 'UPDATE_CAR_BID'

export const UPDATE_CAR_BID_LOADING = 'UPDATE_CAR_BID_LOADING'
export const UPDATE_CAR_BID_PRICE = 'UPDATE_CAR_BID_PRICE'
export const CAR_BID_PRICE_RESET = 'CAR_BID_PRICE_RESET'