import {
    SELECT_MERK,
    SELECT_GROUP_MODEL,
    SELECT_MODEL,
    SELECT_MODEL_YEAR,
    RESET_MERK,
    RESET_GROUP_MODEL,
    RESET_MODEL,
    RESET_MODEL_YEAR,
    BRAND_LIST_REQUEST,
    BRAND_LIST_SUCCESS,
    BRAND_LIST_FAIL,
    GROUP_MODEL_LIST_REQUEST,
    GROUP_MODEL_LIST_SUCCESS,
    GROUP_MODEL_LIST_FAIL,
    GROUP_MODEL_LIST_RESET,
    MODEL_YEAR_LIST_REQUEST,
    MODEL_YEAR_LIST_SUCCESS,
    MODEL_YEAR_LIST_FAIL,
    MODEL_YEAR_LIST_RESET,
    CAR_DETAILS_REQUEST,
    CAR_DETAILS_SUCCESS,
    CAR_DETAILS_FAIL,
    COLOR_REQUEST,
    COLOR_SUCCESS,
    COLOR_FAIL,
    YEAR_OF_USAGE_REQUEST,
    YEAR_OF_USAGE_SUCCESS,
    YEAR_OF_USAGE_FAIL,
    YEAR_OF_USAGE_RESET,
    SET_FOCUS_FIELD,
    MODEL_LIST_BY_YEAR_REQUEST,
    MODEL_LIST_BY_YEAR_SUCCESS,
    MODEL_LIST_BY_YEAR_FAIL,
    MODEL_LIST_BY_YEAR_RESET,
    RESET_ALL_SELECTED_VALUE,
    RESET_FOCUS_FIELD,
    NOT_IS_CLIPAN,
    RESET_CLIPAN_ERROR,
    CAR_DETAILS_SUCCESS_RESET,
    SELECT_MODEL_YEAR_2,
    RESET_MODEL_YEAR_2,
    MODEL_LIST_BY_RANGE_YEAR_REQUEST,
    MODEL_LIST_BY_RANGE_YEAR_SUCCESS,
    MODEL_LIST_BY_RANGE_YEAR_FAIL,
    MODEL_LIST_BY_RANGE_YEAR_RESET,
    RESET_CLIPAN_ERROR_ON_SIMULASI,
    RESET_SECTION,
    MODEL_LIST_REQUEST,
    MODEL_LIST_SUCCESS,
    MODEL_LIST_FAIL,
    MODEL_LIST_RESET,
    CAR_DETAILS_RESET,
} from '../constants/brandConstants'
import { SEARCH_CAR_AMOUNT_FAIL, SEARCH_CAR_AMOUNT_REQUEST, SEARCH_CAR_AMOUNT_RESET, SEARCH_CAR_AMOUNT_SUCCESS, SIMILAR_CARS_FAIL, SIMILAR_CARS_REQUEST, SIMILAR_CARS_SUCCESS } from '../constants/carPerCategoryConstant'
import { 
    RESET_CITY_AT_DJUBLI_CHECK, 
    RESET_EXTERIOR_COLOR_AT_DJUBLI_CHECK, 
    SELECT_CITY_AT_DJUBLI_CHECK, 
    SELECT_EXTERIOR_COLOR_AT_DJUBLI_CHECK
} from '../constants/djubliCheckConstant'
import { 
    RESET_CITY_AT_FILTER, 
    RESET_EXTERIOR_COLOR_AT_FILTER,
    RESET_INTERIOR_COLOR_AT_FILTER,
    RESET_SUBDISTRICT_AT_FILTER,
    SELECT_CITY_AT_FILTER, 
    SELECT_EXTERIOR_COLOR_AT_FILTER, 
    SELECT_INTERIOR_COLOR_AT_FILTER, 
    SELECT_SUBDISTRICT_AT_FILTER 
} from '../constants/filterShowPerCategory'

const initialState = { 
    loading: false,
    success: false,
    error: false,
}

export const selectDropdownReducer = (
    state = { 
        brands: null,
        groupModel: null,
        modelYear: null,
        modelYear2: null,
        model: null,
        disableGroupModel: true,
        disableModelYear: true,
        disableModelYear2: true,
        disableModel: true,
        exteriorColor: null,
        interiorColor: null,
        city: null,
        subDistrict: null,
        focusField: null,
        clipanError: false,
        section: null
    },
    action
) => {
    switch (action.type) {
        case SELECT_MERK:
            return { loading: false, ...state, brands: action.payload, disableGroupModel: false }
        case SELECT_GROUP_MODEL:
            return { loading: false, ...state, groupModel: action.payload, disableModelYear: false }
        // case SELECT_MODEL_YEAR:
        //     return { loading: false, ...state, modelYear: action.payload, disableModel: false }
        case SELECT_MODEL_YEAR:
            return { loading: false, ...state, modelYear: action.payload, disableModelYear2: false }
        case SELECT_MODEL_YEAR_2:
            return { loading: false, ...state, modelYear2: action.payload, disableModel: false }
        case SELECT_MODEL:
            return { loading: false, ...state, model: action.payload }
        case RESET_MERK:
            return { loading: false, ...state, brands: null, disableGroupModel: true }
        // case RESET_GROUP_MODEL:
        //     return { loading: false, ...state, groupModel: null, disableModelYear: true }
        case RESET_GROUP_MODEL:
            return { loading: false, ...state, groupModel: null, disableModelYear: true, disableModelYear2: true }
        case RESET_MODEL_YEAR:
            return { loading: false, ...state, modelYear: null, disableModel: true }
        case RESET_MODEL_YEAR_2:
            return { loading: false, ...state, modelYear2: null, disableModel: true }
        case RESET_MODEL:
            return { loading: false, ...state, model: null }
        case SELECT_EXTERIOR_COLOR_AT_FILTER:
            return { loading: false, ...state, exteriorColor: action.payload, focusField: null }
        case SELECT_INTERIOR_COLOR_AT_FILTER:
            return { loading: false, ...state, interiorColor: action.payload, focusField: null }
        case SELECT_CITY_AT_FILTER:
            return { loading: false, ...state, city: action.payload, focusField: null }
        case SELECT_SUBDISTRICT_AT_FILTER:
            return { loading: false, ...state, subDistrict: action.payload, focusField: null }
        case RESET_EXTERIOR_COLOR_AT_FILTER:
            return { ...state, exteriorColor: null, focusField: null }
        case RESET_INTERIOR_COLOR_AT_FILTER:
            return { ...state, interiorColor: null, focusField: null }
        case RESET_CITY_AT_FILTER:
            return { ...state, city: null, focusField: null }
        case RESET_SUBDISTRICT_AT_FILTER:
            return { ...state, subDistrict: null, focusField: null }
        case SELECT_CITY_AT_DJUBLI_CHECK:
            return { ...state, city: action.payload, focusField: null }
        case SELECT_EXTERIOR_COLOR_AT_DJUBLI_CHECK:
            return { ...state, exteriorColor: action.payload, focusField: null }
        case RESET_CITY_AT_DJUBLI_CHECK:
            return { ...state, city: null, focusField: null }
        case RESET_EXTERIOR_COLOR_AT_DJUBLI_CHECK:
            return { ...state, exteriorColor: null, focusField: null }
        case SET_FOCUS_FIELD:
            return { ...state, focusField: action.payload }
        case RESET_FOCUS_FIELD:
            return { ...state, focusField: null }
        case RESET_ALL_SELECTED_VALUE:
            return { 
                brands: null,
                groupModel: null,
                modelYear: null,
                modelYear2: null,
                model: null,
                disableGroupModel: true,
                disableModelYear: true,
                disableModelYear2: true,
                disableModel: true,
                exteriorColor: null,
                interiorColor: null,
                city: null,
                subDistrict: null,
                focusField: null,
                clipanError: false,
                section: null
            }
        case NOT_IS_CLIPAN:
            return { 
                ...state,
                clipanError: true,
                disableGroupModel: true,
                disableModelYear: true,
                disableModel: true,
                section: null
            }
        case RESET_CLIPAN_ERROR:
            return { ...state, clipanError: false, section: null }
        case RESET_SECTION:
            return { ...state, section: null }
        case RESET_CLIPAN_ERROR_ON_SIMULASI:
            return { ...state, clipanError: false, section: "simulasi" }
        default:
            return state
    }
}

export const carDetailsReducer = (
    state = { loading: false, success: false, error: false, carDetails: [] },
    action
) => {
    switch (action.type) {
        case CAR_DETAILS_REQUEST:
            return { loading: true, success: false, error: false, carDetails: [] }
        case CAR_DETAILS_SUCCESS:
            return { loading: false, carDetails: action.payload, success: true, error: false }
        case CAR_DETAILS_SUCCESS_RESET:
            return { ...state, success: false }
        case CAR_DETAILS_FAIL:
            return { loading: false, success: false, error: action.payload, carDetails: [] }
        case CAR_DETAILS_RESET:
            return { loading: false, success: false, error: false, carDetails: [] }
        default:
            return state
    }
}

export const brandListReducer = (
    state = { brands: [] },
    action
) => {
    switch (action.type) {
        case BRAND_LIST_REQUEST:
            return { loading: true, brands: [] }
        case BRAND_LIST_SUCCESS:
            return { loading: false, brands: action.payload }
        case BRAND_LIST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const groupModelListReducer = (
    state = { groupModels: [] },
    action
) => {
    switch (action.type) {
        case GROUP_MODEL_LIST_REQUEST:
            return { loading: true, groupModels: [] }
        case GROUP_MODEL_LIST_SUCCESS:
            return { loading: false, groupModels: action.payload }
        case GROUP_MODEL_LIST_FAIL:
            return { loading: false, error: action.payload }
        case GROUP_MODEL_LIST_RESET:
                return { loading: false, groupModels: [] }
        default:
            return state
    }
}

export const modelListReducer = (
    state = { models: [] },
    action
) => {
    switch (action.type) {
        case MODEL_LIST_BY_YEAR_REQUEST:
            return { loading: true, models: [] }
        case MODEL_LIST_BY_YEAR_SUCCESS:
            return { loading: false, models: action.payload }
        case MODEL_LIST_BY_YEAR_FAIL:
            return { loading: false, error: action.payload }
        case MODEL_LIST_BY_YEAR_RESET:
            return { loading: false, models: [] }
        case MODEL_LIST_BY_RANGE_YEAR_REQUEST:
            return { loading: true, models: [] }
        case MODEL_LIST_BY_RANGE_YEAR_SUCCESS:
            return { loading: false, models: action.payload }
        case MODEL_LIST_BY_RANGE_YEAR_FAIL:
            return { loading: false, error: action.payload }
        case MODEL_LIST_BY_RANGE_YEAR_RESET:
            return { loading: false, models: [] }
        case MODEL_LIST_REQUEST:
            return { loading: true, models: [] }
        case MODEL_LIST_SUCCESS:
            return { loading: false, models: action.payload }
        case MODEL_LIST_FAIL:
            return { loading: false, error: action.payload }
        case MODEL_LIST_RESET:
            return { loading: false, models: [] }
        default:
            return state
    }
}

export const modelYearListReducer = (
    state = { ...initialState, modelYears: [] },
    action
) => {
    switch (action.type) {
        case MODEL_YEAR_LIST_REQUEST:
            return { loading: true, success: false, error: false, modelYears: [] }
        case MODEL_YEAR_LIST_SUCCESS:
            return { loading: false, success: true, error: false, modelYears: action.payload }
        case MODEL_YEAR_LIST_FAIL:
            return { loading: false, success: false, error: action.payload, modelYears: [] }
        case MODEL_YEAR_LIST_RESET:
                return { ...initialState, modelYears: [] }
        default:
            return state
    }
}

export const colorReducer = (
    state = { colors: [] },
    action
) => {
    switch (action.type) {
        case COLOR_REQUEST:
            return { loading: true, colors: [] }
        case COLOR_SUCCESS:
            return { loading: false, colors: action.payload }
        case COLOR_FAIL:
            return { loading: false, colors: [], error: action.payload }
        default:
            return state
    }
}

export const yearOfUsageReducer = (
    state = { yearOfUsage: [] },
    action
) => {
    switch (action.type) {
        case YEAR_OF_USAGE_REQUEST:
            return { loading: true, yearOfUsage: [] }
        case YEAR_OF_USAGE_SUCCESS:
            return { loading: false, yearOfUsage: action.payload }
        case YEAR_OF_USAGE_FAIL:
            return { loading: false, error: action.payload }
        case YEAR_OF_USAGE_RESET:
            return { loading: false, yearOfUsage: [] }
        default:
            return state
    }
}

export const similarCarsReducer = (
    state = { similarCarList: [] },
    action
) => {
    switch(action.type) {
        case SIMILAR_CARS_REQUEST:
            return { loading: true, similarCarList: [] }
        case SIMILAR_CARS_SUCCESS:
            return { loading: false, similarCarList: action.payload }
        case SIMILAR_CARS_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const searchCountReducer = (
    state = { count: [] },
    action
) => {
    switch(action.type) {
        case SEARCH_CAR_AMOUNT_REQUEST:
            return { loading: true, count: [] }
        case SEARCH_CAR_AMOUNT_SUCCESS:
            return { loading: false, count: action.payload }
        case SEARCH_CAR_AMOUNT_FAIL:
            return { loading: false, error: action.payload }
        case SEARCH_CAR_AMOUNT_RESET:
            return { count: [] }
        default:
            return state
    }
}