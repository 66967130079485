import { 
    ALL_CITY_REQUEST,
    ALL_CITY_SUCCESS,
    ALL_CITY_FAIL,
    SUB_DISTRICT_REQUEST,
    SUB_DISTRICT_SUCCESS,
    SUB_DISTRICT_FAIL,
} from '../constants/areaSubDistrictConstants'

export const cityReducer = (
    state = { 
        loading: false,
        error: false,
        success: false,
        listCity: [],
    },
    action
) => {
    switch (action.type) {
        case ALL_CITY_REQUEST:
            return { 
                loading: true,
                error: false,
                success: false,
                listCity: [] 
            }
        case ALL_CITY_SUCCESS:
            return { 
                loading: false,
                error: false,
                success: true,
                listCity: action.payload 
            }
        case ALL_CITY_FAIL:
            return { 
                loading: false,
                error: action.payload,
                success: false,
                listCity: []
            }
        default:
            return state
    }
}

export const subDistrictReducer = (
    state = { 
        loading: false,
        error: false,
        success: false,
        subDistrict: [] 
    },
    action
) => {
    switch (action.type) {
        case SUB_DISTRICT_REQUEST:
            return { 
                loading: true,
                error: false,
                success: false,
                subDistrict: [] 
            }
        case SUB_DISTRICT_SUCCESS:
            return { 
                loading: false,
                error: false,
                success: true,
                subDistrict: action.payload 
            }
        case SUB_DISTRICT_FAIL:
            return { 
                loading: false,
                success: false,
                error: action.payload,
                subDistrict: []
            }
        default:
            return state
    }
}