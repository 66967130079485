import { 
    REGISTER_OTP_FAIL,
    REGISTER_OTP_REQUEST,
    REGISTER_OTP_SUCCESS,
    SUBMIT_FORM_USER_BUY_FAIL,
    SUBMIT_FORM_USER_BUY_REQUEST,
    SUBMIT_FORM_USER_BUY_SUCCESS,
    USER_LOGIN_FAIL, 
    USER_LOGIN_REQUEST, 
    USER_LOGIN_SUCCESS,
    USER_LOGOUT,
    USER_NO_LOGIN_FAIL,
    USER_NO_LOGIN_REQUEST,
    USER_NO_LOGIN_SUCCESS,
    VERIFY_OTP_REQUEST,
    VERIFY_OTP_SUCCESS,
    VERIFY_OTP_FAIL,
    CHECK_REFERRAL_REQUEST,
    CHECK_REFERRAL_SUCCESS,
    CHECK_REFERRAL_FAIL,
    USER_UPDATE_REQUEST,
    USER_UPDATE_SUCCESS,
    USER_UPDATE_FAIL,
    USER_UPDATE_RESET,
    GET_USER_LOCATION_REQUEST,
    GET_USER_LOCATION_SUCCESS,
    GET_USER_LOCATION_FAIL,
    REGISTER_NO_PASSWORD_REQUEST,
    REGISTER_NO_PASSWORD_FAIL,
    REGISTER_NO_PASSWORD_SUCCESS,
    OTP_NO_PASSWORD_REQUEST,
    OTP_NO_PASSWORD_SUCCESS,
    OTP_NO_PASSWORD_FAIL,
    VERIFY_OTP_NO_PASSWORD_REQUEST,
    VERIFY_OTP_NO_PASSWORD_SUCCESS,
    VERIFY_OTP_NO_PASSWORD_FAIL,
    OTP_NO_PASSWORD_STATUS_RESET,
    VERIFY_OTP_NO_PASSWORD_STATUS_RESET,
    REGISTER_NO_PASSWORD_STATUS_RESET,
    USER_LOGIN_AFTER_REGISTER_NO_PASS,
    USER_LOGIN_AFTER_REGISTER_NO_PASS_LOADING,
    USER_LOGIN_AFTER_REGISTER_NO_PASS_FAIL,
    USER_LOGIN_STATUS_RESET,
    USER_LOGIN_NO_PASS_REQUEST,
    USER_LOGIN_NO_PASS_SUCCESS,
    USER_LOGIN_NO_PASS_FAIL,
    USER_LOGIN_NO_PASS_RESET,
    OTP_LOGIN_SUCCESS,
    OTP_LOGIN_REQUEST,
    OTP_LOGIN_FAIL,
    OTP_LOGIN_STATUS_RESET,
    VERIFY_OTP_LOGIN_SUCCESS,
    VERIFY_OTP_LOGIN_FAIL,
    VERIFY_OTP_LOGIN_STATUS_RESET,
    USER_LOGIN_OTP_REQUEST,
    USER_LOGIN_OTP_SUCCESS,
    USER_LOGIN_OTP_FAIL,
    USER_UPDATE_DATA_LOGIN,
    DELETE_ACCOUNT_REQUEST,
    DELETE_ACCOUNT_SUCCESS,
    DELETE_ACCOUNT_FAIL,
    DELETE_ACCOUNT_RESET,
    CHANGE_PASSWORD_REQUEST,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_FAIL,
    CHANGE_PASSWORD_RESET,
    OTP_DELETE_ACCOUNT_REQUEST,
    OTP_DELETE_ACCOUNT_SUCCESS,
    OTP_DELETE_ACCOUNT_FAIL,
    OTP_DELETE_ACCOUNT_RESET,
    VERIFY_OTP_LEADS_REQUEST,
    VERIFY_OTP_LEADS_SUCCESS,
    VERIFY_OTP_LEADS_FAIL,
    VERIFY_OTP_LEADS_RESET,
} from "../constants/userConstant"

export const userLoginReducer = ( state = { userInfo: null }, action ) => {
    switch (action.type) {
        case USER_LOGIN_REQUEST:
            return { loading: true }
        case USER_LOGIN_SUCCESS:
            return { loading: false, success: true, userInfo: action.payload }
        case USER_LOGIN_FAIL:
            return { loading: false, error: action.payload }
        case USER_LOGIN_AFTER_REGISTER_NO_PASS_LOADING:
            return { loading: true }
        case USER_LOGIN_AFTER_REGISTER_NO_PASS:
            return { ...state, success: true, userInfo: action.payload }
        case USER_LOGIN_AFTER_REGISTER_NO_PASS_FAIL:
            return { loading: false, error: action.payload }
        case USER_LOGIN_STATUS_RESET:
            return { ...state, success: false }
        case USER_LOGIN_OTP_REQUEST:
            return { loading: true, otp: true }
        case USER_LOGIN_OTP_SUCCESS:
            return { loading: false, success: true, userInfo: action.payload, otp: true }
        case USER_LOGIN_OTP_FAIL:
            return { loading: false, error: action.payload, otp: true }
        case USER_LOGOUT:
            return { userInfo: null }
        case USER_UPDATE_DATA_LOGIN:
            return { ...state, userInfo: action.payload }
        default:
            return state
    }
}

export const userRegisterReducer = (
    state = { 
        error: false,
        userRegister: [],
        otpVerify: [],
        referralCodeVerify: {
            loading: false,
            data: {
                success: false,
                status: false
            }
        },
        verified: false,
     },
    action
) => {
    switch (action.type) {
        case REGISTER_OTP_REQUEST:
            return { ...state, error: false, loading: true }
        case REGISTER_OTP_SUCCESS:
            return { ...state, error: false, loading: false, userRegister: action.payload }
        case REGISTER_OTP_FAIL:
            return { ...state, loading: false, error: action.payload }
        case VERIFY_OTP_REQUEST:
            return { ...state, error: false, loading: true }
        case VERIFY_OTP_SUCCESS:
            return { ...state, error: false, loading: false, verified: true, otpVerify: action.payload }
        case VERIFY_OTP_FAIL:
            return { ...state, loading: false, error: action.payload }
        case CHECK_REFERRAL_REQUEST:
            return { ...state, referralCodeVerify: { loading: true, data: { success: false, data: '' } } }
        case CHECK_REFERRAL_SUCCESS:
            return { ...state, referralCodeVerify: { loading: false, data: action.payload } }
        case CHECK_REFERRAL_FAIL:
            return { ...state, referralCodeVerify: { loading: false, data: action.payload } }
        default:
            return state
    }
}

export const userBuyCarCaptureReducer = (
    state = { 
        formData: []
     },
    action
) => {
    switch (action.type) {
        case SUBMIT_FORM_USER_BUY_REQUEST:
            return { loading: true }
        case SUBMIT_FORM_USER_BUY_SUCCESS:
            return { loading: false, formData: action.payload }
        case SUBMIT_FORM_USER_BUY_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const userProfileNoLoginReducer = (
    state = { userData: [] },
    action
) => {
    switch (action.type) {
        case USER_NO_LOGIN_REQUEST:
            return { loading: true, userData: [] }
        case USER_NO_LOGIN_SUCCESS:
            return { loading: false, userData: action.payload }
        case USER_NO_LOGIN_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const userUpdateProfileReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_UPDATE_REQUEST:
            return { loading: true }
        case USER_UPDATE_SUCCESS:
            return { loading: false, sucess: true, userInfo: action.payload }
        case USER_UPDATE_FAIL:
            return { loading: false, error: action.payload }
        case USER_UPDATE_RESET:
            return { }
        default:
            return state
    }
}

export const getUserLocationReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_USER_LOCATION_REQUEST:
            return { loading: true }
        case GET_USER_LOCATION_SUCCESS:
            return { loading: false, userLocation: action.payload }
        case GET_USER_LOCATION_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const userRegisterNoPasswordReducer = (
    state = { register: null },
    action
) => {
    switch (action.type) {
        case REGISTER_NO_PASSWORD_REQUEST:
            return { loading: true }
        case REGISTER_NO_PASSWORD_SUCCESS:
            return { loading: false, register: action.payload }
        case REGISTER_NO_PASSWORD_FAIL:
            return { loading: false, error: action.payload }
        case REGISTER_NO_PASSWORD_STATUS_RESET:
            return { ...state, register: { ...state.register, success: false }, registerNoPass: true }
        default:
            return state
    }
}

export const otpNoPasswordReducer = (
    state = { data: null },
    action
) => {
    switch (action.type) {
        case OTP_NO_PASSWORD_REQUEST:
            return { loading: true, register: true }
        case OTP_NO_PASSWORD_SUCCESS:
            return { loading: false, data: action.payload, register: true }
        case OTP_NO_PASSWORD_FAIL:
            return { loading: false, error: action.payload, register: true }
        case OTP_NO_PASSWORD_STATUS_RESET:
            return { ...state, data: { ...state.data, success: false }, error: false, register: true, login: false }
        case OTP_LOGIN_REQUEST:
            return { loading: true, login: true }
        case OTP_LOGIN_SUCCESS:
            return { loading: false, data: action.payload, login: true }
        case OTP_LOGIN_FAIL:
            return { loading: false, error: action.payload, login: true }
        case OTP_LOGIN_STATUS_RESET:
            return { ...state, data: { ...state.data, success: false }, error: false, login: true, register: false }
        default:
            return state
    }
}

export const verifyOtpNoPasswordReducer = (
    state = { verify: null },
    action
) => {
    switch (action.type) {
        case VERIFY_OTP_NO_PASSWORD_REQUEST:
            return { loading: true }
        case VERIFY_OTP_NO_PASSWORD_SUCCESS:
            return { loading: false, verify: action.payload, register: true }
        case VERIFY_OTP_NO_PASSWORD_FAIL:
            return { loading: false, error: action.payload, register: true }
        case VERIFY_OTP_NO_PASSWORD_STATUS_RESET:
            return { ...state, verify: { ...state.verify, success: false }, register: true }
        case VERIFY_OTP_LOGIN_SUCCESS:
            return { loading: false, verify: action.payload, login: true }
        case VERIFY_OTP_LOGIN_FAIL:
            return { loading: false, error: action.payload, login: true }
        case VERIFY_OTP_LOGIN_STATUS_RESET:
            return { ...state, verify: { ...state.verify, success: false }, login: true }
        default:
            return state
    }
}

export const userLoginNoPasswordReducer = (
    state = { data: null },
    action
) => {
    switch (action.type) {
        case USER_LOGIN_NO_PASS_REQUEST:
            return { loading: true }
        case USER_LOGIN_NO_PASS_SUCCESS:
            return { loading: false, data: action.payload }
        case USER_LOGIN_NO_PASS_FAIL:
            return { loading: false, error: action.payload }
        case USER_LOGIN_NO_PASS_RESET:
            return { ...state, data: { ...state.data, success: false }, error: false }
        default:
            return state
    }
}

export const deleteAccountReducer = (
    state = { loading: false, success: false, data: null },
    action
) => {
    switch (action.type) {
        case DELETE_ACCOUNT_REQUEST:
            return { loading: true }
        case DELETE_ACCOUNT_SUCCESS:
            return { loading: false, data: action.payload }
        case DELETE_ACCOUNT_FAIL:
            return { loading: false, error: action.payload }
        case DELETE_ACCOUNT_RESET:
            return { ...state, data: null, error: false, success: false, loading: false }
        default:
            return state
    }   
}

export const deleteAccountRequestOtpReducer = (
    state = { loading: false, success: false, otp: null },
    action
) => {
    switch (action.type) {
        case OTP_DELETE_ACCOUNT_REQUEST:
            return { loading: true, success: false }
        case OTP_DELETE_ACCOUNT_SUCCESS:
            return { loading: false, success: true, otp: action.payload }
        case OTP_DELETE_ACCOUNT_FAIL:
            return { loading: false, success: false, error: action.payload }
        case OTP_DELETE_ACCOUNT_RESET:
            return { loading: false, success: false, otp: null }
        default:
            return state
    }   
}

export const changePasswordReducer = (
    state = { loading: false, success: false, changePass: null },
    action
) => {
    switch (action.type) {
        case CHANGE_PASSWORD_REQUEST:
            return { loading: true, success: false }
        case CHANGE_PASSWORD_SUCCESS:
            return { loading: false, success: true, changePass: action.payload }
        case CHANGE_PASSWORD_FAIL:
            return { loading: false, success: false, error: action.payload }
        case CHANGE_PASSWORD_RESET:
            return { loading: false, success: false, changePass: null }
        default:
            return state
    }   
}

export const verifyOtpLeadsReducer = (
    state = { loading: false, success: false, verifyOtp: null },
    action
) => {
    switch (action.type) {
        case VERIFY_OTP_LEADS_REQUEST:
            return { loading: true, success: false }
        case VERIFY_OTP_LEADS_SUCCESS:
            return { loading: false, success: true, verifyOtp: action.payload }
        case VERIFY_OTP_LEADS_FAIL:
            return { loading: false, success: false, error: action.payload }
        case VERIFY_OTP_LEADS_RESET:
            return { loading: false, success: false, verifyOtp: null }
        default:
            return state
    }   
}