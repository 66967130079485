export const GET_CATEGORIES_REQUEST = 'GET_CATEGORIES_REQUEST'
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS'
export const GET_CATEGORIES_FAIL = 'GET_CATEGORIES_FAIL'
export const SWITCH_CATEGORIES_REQUEST = 'GET_CATEGORIES_REQUEST'
export const SWITCH_CATEGORIES_SUCCESS = 'SWITCH_CATEGORIES_SUCCESS'

export const PREMIUM_CARS_REQUEST = 'PREMIUM_CARS_REQUEST'
export const PREMIUM_CARS_LIST_SUCCESS = 'PREMIUM_CARS_LIST_SUCCESS'
export const PREMIUM_CARS_LIST_FAIL = 'PREMIUM_CARS_LIST_FAIL'

export const INSPEKSI_CARS_REQUEST = 'INSPEKSI_CARS_REQUEST'
export const INSPEKSI_CARS_LIST_SUCCESS = 'INSPEKSI_CARS_LIST_SUCCESS'
export const INSPEKSI_CARS_LIST_FAIL = 'INSPEKSI_CARS_LIST_FAIL'

export const LOW_KM_REQUEST = 'LOW_KM_REQUEST'
export const LOW_KM_LIST_SUCCESS = 'LOW_KM_LIST_SUCCESS'
export const LOW_KM_LIST_FAIL = 'LOW_KM_LIST_FAIL'

export const QUERY_CARS_REQUEST = 'QUERY_CARS_REQUEST'
export const QUERY_CARS_LIST_SUCCESS = 'QUERY_CARS_LIST_SUCCESS'
export const QUERY_CARS_LIST_FAIL = 'QUERY_CARS_LIST_FAIL'

export const USER_CARS_REQUEST = 'USER_CARS_REQUEST'
export const USER_CARS_LIST_SUCCESS = 'USER_CARS_LIST_SUCCESS'
export const USER_CARS_LIST_FAIL = 'USER_CARS_LIST_FAIL'
export const USER_CARS_LIST_RESET = 'USER_CARS_LIST_RESET'

export const DELETE_LISTING_CAR_REQUEST = 'DELETE_LISTING_CAR_REQUEST'
export const DELETE_LISTING_CAR_SUCCESS = 'DELETE_LISTING_CAR_SUCCESS'
export const DELETE_LISTING_CAR_FAIL = 'DELETE_LISTING_CAR_FAIL'
export const DELETE_LISTING_CAR_RESET = 'DELETE_LISTING_CAR_RESET'

export const PURCHASES_LIST_REQUEST = 'PURCHASES_LIST_REQUEST'
export const PURCHASES_LIST_SUCCESS = 'PURCHASES_LIST_SUCCESS'
export const PURCHASES_LIST_FAIL = 'PURCHASES_LIST_FAIL'
export const PURCHASES_LIST_RESET = 'PURCHASES_LIST_RESET'

export const CAR_HERO_REQUEST = 'CAR_HERO_REQUEST'
export const CAR_HERO_SUCCESS = 'CAR_HERO_SUCCESS'
export const CAR_HERO_FAIL = 'CAR_HERO_FAIL'
export const CAR_HERO_RESET = 'CAR_HERO_RESET'

export const SELECT_HERO_CATEGORY = 'SELECT_HERO_CATEGORY'
export const RESET_HERO_CATEGORY = 'RESET_HERO_CATEGORY'

export const SIMILAR_CARS_REQUEST = 'SIMILAR_CARS_REQUEST'
export const SIMILAR_CARS_SUCCESS = 'SIMILAR_CARS_SUCCESS'
export const SIMILAR_CARS_FAIL = 'SIMILAR_CARS_FAIL'

export const CAR_COUNT_REQUEST = 'CAR_COUNT_REQUEST'
export const CAR_COUNT_SUCCESS = 'CAR_COUNT_SUCCESS'
export const CAR_COUNT_FAIL = 'CAR_COUNT_FAIL'
export const CAR_COUNT_RESET = 'CAR_COUNT_RESET'

export const SEARCH_CAR_AMOUNT_REQUEST = 'SEARCH_CAR_AMOUNT_REQUEST'
export const SEARCH_CAR_AMOUNT_SUCCESS = 'SEARCH_CAR_AMOUNT_SUCCESS'
export const SEARCH_CAR_AMOUNT_FAIL = 'SEARCH_CAR_AMOUNT_FAIL'
export const SEARCH_CAR_AMOUNT_RESET = 'SEARCH_CAR_AMOUNT_RESET'

export const GET_AUCTION_CARS_CAROUSEL_REQUEST = 'GET_AUCTION_CARS_CAROUSEL_REQUEST'
export const GET_AUCTION_CARS_CAROUSEL_SUCCESS = 'GET_AUCTION_CARS_CAROUSEL_SUCCESS'
export const GET_AUCTION_CARS_CAROUSEL_FAIL = 'GET_AUCTION_CARS_CAROUSEL_FAIL'