import React from 'react'
import { hydrate, render } from "react-dom"
import { HelmetProvider } from 'react-helmet-async'
import { Provider } from 'react-redux'
import './index.min.css'
import store from './store'
import App from './App'
import reportWebVitals from './reportWebVitals'

const app = (
  <Provider store={store}>
    <HelmetProvider>
      <App />
    </HelmetProvider>
  </Provider>
)
 
const rootElement = document.getElementById("root")

if (rootElement.hasChildNodes()) {
  hydrate(app, rootElement)
} else {
  render(app, rootElement)
}

// ReactDOM.render(
//   <Provider store={store}>
//     <HelmetProvider>
//       <App />
//     </HelmetProvider>
//   </Provider>,
//   document.getElementById('root')
// )

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()