import {
    CAR_INSPECTION_REQUEST,
    CAR_INSPECTION_LIST_SUCCESS,
    CAR_INSPECTION_LIST_FAIL,
    CAR_INSPECTION_RESET
} from '../constants/carInspectionConstants'

export const carInspectionReducer = (
    state = { 
        loading: false,
        error: false,
        success: false,
        carInspection: []
    },
    action
) => {
    switch (action.type) {
        case CAR_INSPECTION_REQUEST:
            return { loading: true, error: false, success: false, carInspection: [] }
        case CAR_INSPECTION_LIST_SUCCESS:
            return { loading: false, error: false, success: true, carInspection: action.payload }
        case CAR_INSPECTION_LIST_FAIL:
            return { loading: false, error: action.payload, success: false, carInspection: [] }
        case CAR_INSPECTION_RESET:
            return { loading: false, error: false, success: false, carInspection: [] }
        default:
            return state
    }
}