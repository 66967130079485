import { 
    APPLY_CREDIT_KKB_FAIL,
    APPLY_CREDIT_KKB_REQUEST,
    APPLY_CREDIT_KKB_RESET,
    APPLY_CREDIT_KKB_SUCCESS,
    GET_KKB_ESTIMATION_FAIL,
    GET_KKB_ESTIMATION_REQUEST,
    GET_KKB_ESTIMATION_RESET,
    GET_KKB_ESTIMATION_SUCCESS,
    GET_OTHER_PLATFORM_FAIL,
    GET_OTHER_PLATFORM_REQUEST,
    GET_OTHER_PLATFORM_RESET,
    GET_OTHER_PLATFORM_SUCCESS,
    GET_OTHER_PROMO_CARS_FAIL,
    GET_OTHER_PROMO_CARS_REQUEST,
    GET_OTHER_PROMO_CARS_RESET,
    GET_OTHER_PROMO_CARS_SUCCESS,
    GET_PROMO_BANNER_FAIL,
    GET_PROMO_BANNER_REQUEST,
    GET_PROMO_BANNER_RESET,
    GET_PROMO_BANNER_SUCCESS,
    GET_PROMO_BY_MYID_FAIL,
    GET_PROMO_BY_MYID_REQUEST,
    GET_PROMO_BY_MYID_RESET,
    GET_PROMO_BY_MYID_SUCCESS,
    GET_PROMO_CAR_LIST_FAIL,
    GET_PROMO_CAR_LIST_REQUEST,
    GET_PROMO_CAR_LIST_RESET,
    GET_PROMO_CAR_LIST_SUCCESS
} from "../constants/promoConstants"

export const bannerPromoReducers = ( 
    state = { 
        loading: false,
        success: false,
        error: false,
        banner: [],
    },
    action
) => {
    switch (action.type) {
        case GET_PROMO_BANNER_REQUEST:
            return { loading: true, error: false, success: false, banner: [] }
        case GET_PROMO_BANNER_SUCCESS:
            return { loading: false, error: false, success: true, banner: action.payload }
        case GET_PROMO_BANNER_FAIL:
            return { loading: false, error: action.payload, success: false, banner: false }
        case GET_PROMO_BANNER_RESET:
            return { loading: false, error: false, success: false, banner: [] }
        default:
            return state
    }
}

export const promoCarsReducer = ( 
    state = { 
        loading: false,
        success: false,
        error: false,
        cars: [],
    },
    action
) => {
    switch (action.type) {
        case GET_PROMO_CAR_LIST_REQUEST:
            return { loading: true, error: false, success: false, cars: [] }
        case GET_PROMO_CAR_LIST_SUCCESS:
            return { loading: false, error: false, success: true, cars: action.payload }
        case GET_PROMO_CAR_LIST_FAIL:
            return { loading: false, error: action.payload, success: false, cars: false }
        case GET_PROMO_CAR_LIST_RESET:
            return { loading: false, error: false, success: false, cars: [] }
        default:
            return state
    }
}

export const promoProgramReducer = ( 
    state = { 
        loading: false,
        success: false,
        error: false,
        promo: [],
    },
    action
) => {
    switch (action.type) {
        case GET_PROMO_BY_MYID_REQUEST:
            return { loading: true, error: false, success: false, promo: [] }
        case GET_PROMO_BY_MYID_SUCCESS:
            return { loading: false, error: false, success: true, promo: action.payload }
        case GET_PROMO_BY_MYID_FAIL:
            return { loading: false, error: action.payload, success: false, promo: false }
        case GET_PROMO_BY_MYID_RESET:
            return { loading: false, error: false, success: false, promo: [] }
        default:
            return state
    }
}

export const otherPromoCarsReducer = ( 
    state = { 
        loading: false,
        success: false,
        error: false,
        promoCars: [],
    },
    action
) => {
    switch (action.type) {
        case GET_OTHER_PROMO_CARS_REQUEST:
            return { loading: true, error: false, success: false, promoCars: [] }
        case GET_OTHER_PROMO_CARS_SUCCESS:
            return { loading: false, error: false, success: true, promoCars: action.payload }
        case GET_OTHER_PROMO_CARS_FAIL:
            return { loading: false, error: action.payload, success: false, promoCars: false }
        case GET_OTHER_PROMO_CARS_RESET:
            return { loading: false, error: false, success: false, promoCars: [] }
        default:
            return state
    }
}

export const applyCreditReducer = ( 
    state = { 
        loading: false,
        success: false,
        error: false,
        response: null,
    },
    action
) => {
    switch (action.type) {
        case APPLY_CREDIT_KKB_REQUEST:
            return { loading: true, error: false, success: false, response: null }
        case APPLY_CREDIT_KKB_SUCCESS:
            return { loading: false, error: false, success: true, response: action.payload }
        case APPLY_CREDIT_KKB_FAIL:
            return { loading: false, error: action.payload, success: false, response: null }
        case APPLY_CREDIT_KKB_RESET:
            return { loading: false, error: false, success: false, response: null }
        default:
            return state
    }
}

export const getOtherPlatformReducer = ( 
    state = { 
        loading: false,
        success: false,
        error: false,
        platform: null,
    },
    action
) => {
    switch (action.type) {
        case GET_OTHER_PLATFORM_REQUEST:
            return { loading: true, error: false, success: false, platform: null }
        case GET_OTHER_PLATFORM_SUCCESS:
            return { loading: false, error: false, success: true, platform: action.payload }
        case GET_OTHER_PLATFORM_FAIL:
            return { loading: false, error: action.payload, success: false, platform: null }
        case GET_OTHER_PLATFORM_RESET:
            return { loading: false, error: false, success: false, platform: null }
        default:
            return state
    }
}

export const getKkbEstimationReducer = ( 
    state = { 
        loading: false,
        success: false,
        error: false,
        estimation: null,
    },
    action
) => {
    switch (action.type) {
        case GET_KKB_ESTIMATION_REQUEST:
            return { loading: true, error: false, success: false, estimation: null }
        case GET_KKB_ESTIMATION_SUCCESS:
            return { loading: false, error: false, success: true, estimation: action.payload }
        case GET_KKB_ESTIMATION_FAIL:
            return { loading: false, error: action.payload, success: false, estimation: null }
        case GET_KKB_ESTIMATION_RESET:
            return { loading: false, error: false, success: false, estimation: null }
        default:
            return state
    }
}