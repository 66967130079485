import { 
    CAR_MARKET_PRICE_CUSTOM,
    CAR_MARKET_PRICE_FAIL,
    CAR_MARKET_PRICE_REQUEST,
    CAR_MARKET_PRICE_RESET,
    CAR_MARKET_PRICE_SUCCESS
} from "../constants/djubliKreditConstant"

export const marketPriceVariantReducer = (
    state = { marketPrice: null },
    action
) => {
    switch (action.type) {
        case CAR_MARKET_PRICE_REQUEST:
            return { loading: true, marketPrice: null }
        case CAR_MARKET_PRICE_SUCCESS:
            return { loading: false, success: true, marketPrice: action.payload }
        case CAR_MARKET_PRICE_CUSTOM:
            return { loading: false, success: true, marketPrice: { success: true, data: action.payload }}
        case CAR_MARKET_PRICE_FAIL:
            return { loading: false, error: action.payload }
        case CAR_MARKET_PRICE_RESET:
            return { marketPrice: null }
        default:
            return state
    }
}