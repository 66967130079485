import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
// REDUCERS
import { 
    brandListReducer,
    groupModelListReducer, 
    selectDropdownReducer,
    modelListReducer,
    modelYearListReducer,
    carDetailsReducer,
    colorReducer,
    yearOfUsageReducer,
    similarCarsReducer,
    searchCountReducer
} from './reducers/carReducer'
import { carInspectionReducer } from './reducers/carInspectionReducer'
import {
    premiumCarListReducer,
    inspeksiCarListReducer,
    queryCarListReducer,
    userCarListReducer,
    // lowKmCarListReducer,
    deleteCarFromListingReducer,
    carPurchasesReducer,
    categoriesReducer,
    carHeroReducer,
    selectHeroCategoryReducer,
    auctionCarListCarouselReducer
} from './reducers/carPerCategoryReducer'
import { getPriceByDjubliReducer } from './reducers/sellCarReducer'
import { cityReducer, subDistrictReducer } from './reducers/areaSubDistrictReducer'
import { 
    userBuyCarCaptureReducer,
    userProfileNoLoginReducer,
    userLoginReducer,
    userRegisterReducer,
    userUpdateProfileReducer,
    getUserLocationReducer,
    verifyOtpNoPasswordReducer,
    otpNoPasswordReducer,
    userRegisterNoPasswordReducer,
    changePasswordReducer,
    deleteAccountRequestOtpReducer,
    deleteAccountReducer,
    verifyOtpLeadsReducer
} from './reducers/userReducers'
import { cairBpkbReducer } from './reducers/cairBpkbReducer'
import { marketPriceVariantReducer } from './reducers/djubliKreditReducer'
import { dealerByNameSearchReducer, dealerPerCategoryReducer, dealersReducer, dealerWithMostListingReducer } from './reducers/dealerReduces'
import { bankListReducer, estimationSimulationReducer, getSlikStatusReducer, submitCekSlikReducer, submitDanaTunaiReducer, uploadDanaTunaiReducer, variantByForeignReducer, variantByForeignWebReducer } from './reducers/danaTunaiReducer'
import { applyCreditReducer, bannerPromoReducers, getKkbEstimationReducer, getOtherPlatformReducer, otherPromoCarsReducer, promoCarsReducer, promoProgramReducer } from './reducers/promoReducer'
import { marketLineDataReducer, marketPopularCarsReducer } from './reducers/marketReducer'
import { auctionBidListReducer, auctionDetailReducer, auctionHallDetailReducer, auctionHighestBidPriceReducer, auctionScheduleReducer, onlineAuctionScheduleCarouselReducer, onsiteAuctionScheduleCarouselReducer } from './reducers/auctionReducer'

const reducer = combineReducers({
    bankList: bankListReducer,
    brandList: brandListReducer,
    groupModelList: groupModelListReducer,
    modelList: modelListReducer,
    modelYearList: modelYearListReducer,
    yearOfUsageList: yearOfUsageReducer,
    colorList: colorReducer,
    cityList: cityReducer,
    subDistrictList: subDistrictReducer,
    carCategories: categoriesReducer,
    inspeksiCarList: inspeksiCarListReducer,
    queryCars: queryCarListReducer,
    userCars: userCarListReducer,
    carPurchases: carPurchasesReducer,
    carDetails: carDetailsReducer,
    carHero: carHeroReducer,
    carHeroCategorySelect: selectHeroCategoryReducer,
    premiumCarList: premiumCarListReducer,
    carInspection: carInspectionReducer,
    carSimilar: similarCarsReducer,
    deleteCars: deleteCarFromListingReducer,
    selectedDropdown: selectDropdownReducer,
    cairBpkb: cairBpkbReducer,
    userDataCaptureAtBuy: userBuyCarCaptureReducer,
    profileUser: userProfileNoLoginReducer,
    userLogin: userLoginReducer,
    userRegister: userRegisterReducer,
    userRegisterNoPassword: userRegisterNoPasswordReducer,
    userLocation: getUserLocationReducer,
    userUpdateProfile: userUpdateProfileReducer,
    userRequestOtpNoPassword: otpNoPasswordReducer,
    userVerifyOtpNoPassword: verifyOtpNoPasswordReducer,
    userChangePassword: changePasswordReducer,
    verifyOtpLeads: verifyOtpLeadsReducer,
    marketPriceVariant: marketPriceVariantReducer,
    predictPrice: getPriceByDjubliReducer,
    dealers: dealersReducer,
    dealersMostListing: dealerWithMostListingReducer,
    dealerByName: dealerByNameSearchReducer,
    dealerPerCategory: dealerPerCategoryReducer,
    variantByForeign: variantByForeignReducer,
    variantByForeignWeb: variantByForeignWebReducer,
    danaTunaiSubmit: submitDanaTunaiReducer,
    uploadDokumenDanaTunai: uploadDanaTunaiReducer,
    danaTunaiCekSlik: submitCekSlikReducer,
    userSlik: getSlikStatusReducer,
    deleteAccountOtp: deleteAccountRequestOtpReducer,
    deleteAccount: deleteAccountReducer,
    searchCount: searchCountReducer,
    estimationSimulation: estimationSimulationReducer,
    bannerPromo: bannerPromoReducers,
    promoCarList: promoCarsReducer,
    promoByModelYearId: promoProgramReducer,
    promoOtherCars: otherPromoCarsReducer,
    applyCreditKKB: applyCreditReducer,
    otherPlatformCars: getOtherPlatformReducer,
    estimationKKBSimulation: getKkbEstimationReducer,
    marketLineData: marketLineDataReducer,
    marketPopularCars: marketPopularCarsReducer,
    auctionScheduleList: auctionScheduleReducer,
    onlineAuctionScheduleCarousel: onlineAuctionScheduleCarouselReducer,
    onsiteAuctionScheduleCarousel: onsiteAuctionScheduleCarouselReducer,
    auctionScheduleDetail: auctionDetailReducer,
    auctionHallDetail: auctionHallDetailReducer,
    auctionCarListCarousel: auctionCarListCarouselReducer,
    auctionCarBidList: auctionBidListReducer,
    auctionHighestPriceBid: auctionHighestBidPriceReducer
})

const userInfoFromStorage = localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user'))
    : null

const initialState = {
    userLogin: { userInfo: userInfoFromStorage }
}

const middleware = [thunk]

const store = createStore(
    reducer,
    initialState, 
    composeWithDevTools(applyMiddleware(...middleware))
)

export default store