// SELECTED VALUE
export const SELECT_MERK = 'SELECT_MERK'
export const SELECT_GROUP_MODEL = 'SELECT_GROUP_MODEL'
export const SELECT_MODEL = 'SELECT_MODEL'
export const SELECT_MODEL_YEAR = 'SELECT_MODEL_YEAR'
export const SELECT_MODEL_YEAR_2 = 'SELECT_MODEL_YEAR_2'
export const SET_FOCUS_FIELD = 'SET_FOCUS_FIELD'

// RESET VALUE FROM SELECTED VALUE
export const RESET_MERK = 'RESET_MERK'
export const RESET_GROUP_MODEL = 'RESET_GROUP_MODEL'
export const RESET_MODEL = 'RESET_MODEL'
export const RESET_MODEL_YEAR = 'RESET_MODEL_YEAR'
export const RESET_MODEL_YEAR_2 = 'RESET_MODEL_YEAR_2'
export const RESET_FOCUS_FIELD = 'RESET_FOCUS_FIELD'
export const RESET_ALL_SELECTED_VALUE = 'RESET_ALL_SELECTED_VALUE'

// CLIPAN DANA TUNAI ATTRIBUTE
export const NOT_IS_CLIPAN = 'NOT_IS_CLIPAN'
export const RESET_CLIPAN_ERROR = 'RESET_CLIPAN_ERROR'
export const RESET_CLIPAN_ERROR_ON_SIMULASI = 'RESET_CLIPAN_ERROR_ON_SIMULASI'
export const RESET_SECTION = 'RESET_SECTION'

// FETCH DATA
export const BRAND_LIST_REQUEST = 'BRAND_LIST_REQUEST'
export const BRAND_LIST_SUCCESS = 'BRAND_LIST_SUCCESS'
export const BRAND_LIST_FAIL = 'BRAND_LIST_FAIL'

export const GROUP_MODEL_LIST_REQUEST = 'GROUP_MODEL_LIST_REQUEST'
export const GROUP_MODEL_LIST_SUCCESS = 'GROUP_MODEL_LIST_SUCCESS'
export const GROUP_MODEL_LIST_FAIL = 'GROUP_MODEL_LIST_FAIL'
export const GROUP_MODEL_LIST_RESET = 'GROUP_MODEL_LIST_RESET'

export const MODEL_LIST_REQUEST = 'MODEL_LIST_REQUEST'
export const MODEL_LIST_SUCCESS = 'MODEL_LIST_SUCCESS'
export const MODEL_LIST_FAIL = 'MODEL_LIST_FAIL'
export const MODEL_LIST_RESET = 'MODEL_LIST_RESET'

export const MODEL_LIST_BY_YEAR_REQUEST = 'MODEL_LIST_BY_YEAR_REQUEST'
export const MODEL_LIST_BY_YEAR_SUCCESS = 'MODEL_LIST_BY_YEAR_SUCCESS'
export const MODEL_LIST_BY_YEAR_FAIL = 'MODEL_LIST_BY_YEAR_FAIL'
export const MODEL_LIST_BY_YEAR_RESET = 'MODEL_LIST_BY_YEAR_RESET'

export const MODEL_LIST_BY_RANGE_YEAR_REQUEST = 'MODEL_LIST_BY_RANGE_YEAR_REQUEST'
export const MODEL_LIST_BY_RANGE_YEAR_SUCCESS = 'MODEL_LIST_BY_RANGE_YEAR_SUCCESS'
export const MODEL_LIST_BY_RANGE_YEAR_FAIL = 'MODEL_LIST_BY_RANGE_YEAR_FAIL'
export const MODEL_LIST_BY_RANGE_YEAR_RESET = 'MODEL_LIST_BY_RANGE_YEAR_RESET'

export const MODEL_YEAR_LIST_REQUEST = 'MODEL_YEAR_LIST_REQUEST'
export const MODEL_YEAR_LIST_SUCCESS = 'MODEL_YEAR_LIST_SUCCESS'
export const MODEL_YEAR_LIST_FAIL = 'MODEL_YEAR_LIST_FAIL'
export const MODEL_YEAR_LIST_RESET = 'MODEL_YEAR_LIST_RESET'

export const YEAR_OF_USAGE_REQUEST = 'YEAR_OF_USAGE_REQUEST'
export const YEAR_OF_USAGE_SUCCESS = 'YEAR_OF_USAGE_SUCCESS'
export const YEAR_OF_USAGE_FAIL = 'YEAR_OF_USAGE_FAIL'
export const YEAR_OF_USAGE_RESET = 'YEAR_OF_USAGE_RESET'

export const COLOR_REQUEST = 'COLOR_REQUEST'
export const COLOR_SUCCESS = 'COLOR_SUCCESS'
export const COLOR_FAIL = 'COLOR_FAIL'

export const CAR_DETAILS_REQUEST = 'CAR_DETAILS_REQUEST'
export const CAR_DETAILS_SUCCESS = 'CAR_DETAILS_SUCCESS'
export const CAR_DETAILS_FAIL = 'CAR_DETAILS_FAIL'
export const CAR_DETAILS_SUCCESS_RESET = 'CAR_DETAILS_SUCCESS_RESET'
export const CAR_DETAILS_RESET = 'CAR_DETAILS_RESET'