import { 
    ALL_DEALER_FAIL,
    ALL_DEALER_REQUEST,
    ALL_DEALER_RESET,
    ALL_DEALER_SUCCESS,
    DEALER_BY_NAME_FAIL,
    DEALER_BY_NAME_REQUEST,
    DEALER_BY_NAME_RESET,
    DEALER_BY_NAME_SUCCESS,
    DEALER_PER_CATEGORY_FAIL,
    DEALER_PER_CATEGORY_REQUEST,
    DEALER_PER_CATEGORY_RESET,
    DEALER_PER_CATEGORY_SUCCESS,
    MOST_LISTING_DEALER_FAIL,
    MOST_LISTING_DEALER_REQUEST,
    MOST_LISTING_DEALER_RESET,
    MOST_LISTING_DEALER_SUCCESS
} from "../constants/dealerConstant"

export const dealersReducer = ( 
    state = { dealers: null },
    action
) => {
    switch (action.type) {
        case ALL_DEALER_REQUEST:
            return { loading: true, dealers: null }
        case ALL_DEALER_SUCCESS:
            return { loading: false, success: true, dealers: action.payload }
        case ALL_DEALER_FAIL:
            return { loading: false, error: action.payload }
        case ALL_DEALER_RESET:
            return { dealers: null }
        default:
            return state
    }
}

export const dealerWithMostListingReducer = ( 
    state = { dealers: null },
    action
) => {
    switch (action.type) {
        case MOST_LISTING_DEALER_REQUEST:
            return { loading: true, dealers: null }
        case MOST_LISTING_DEALER_SUCCESS:
            return { loading: false, success: true, dealers: action.payload }
        case MOST_LISTING_DEALER_FAIL:
            return { loading: false, error: action.payload }
        case MOST_LISTING_DEALER_RESET:
            return { dealers: null }
        default:
            return state
    }
}

export const dealerByNameSearchReducer = (
    state = { dealers: null },
    action
) => {
    switch (action.type) {
        case DEALER_BY_NAME_REQUEST:
            return { loading: true, dealers: null }
        case DEALER_BY_NAME_SUCCESS:
            return { loading: false, success: true, dealers: action.payload }
        case DEALER_BY_NAME_FAIL:
            return { loading: false, error: action.payload }
        case DEALER_BY_NAME_RESET:
            return { dealers: null }
        default:
            return state
    }
}

export const dealerPerCategoryReducer = (
    state = { dealers: null },
    action
) => {
    switch (action.type) {
        case DEALER_PER_CATEGORY_REQUEST:
            return { loading: true, dealers: null }
        case DEALER_PER_CATEGORY_SUCCESS:
            return { loading: false, success: true, dealers: action.payload }
        case DEALER_PER_CATEGORY_FAIL:
            return { loading: false, error: action.payload }
        case DEALER_PER_CATEGORY_RESET:
            return { dealers: null }
        default:
            return state
    }
}